import React, { ReactNode } from "react";
import * as Redux from "react-redux";
import { withRouter, RouteComponentProps } from "react-router";
import "styles/page.scss";
import { Typography, Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/MoreHoriz";
import { strings } from "content";
import UpdateTextDialog from "components/GenericDialogs/UpdateTextDialog";
import { StoreState } from "types/store";
import { isRequestLoading } from "store/reducers/apiRequests";

export interface SettingsData {
  id: string;
  label: string;
  onClick: () => void;
}

interface NameChangeParams {
  updateName: (name: string, onComplete: () => void) => void;
  requestId: string;
  currentName?: string;
  title?: string;
  description?: string;
}

export interface OwnProps {
  title: string;
  settings?: SettingsData[];
  children?: ReactNode;
  updateNameParams?: NameChangeParams;
}

interface StateProps {
  isBusyRenaming: boolean;
}

interface DispatchProps {}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps;

const Page: React.FC<Props> = ({ updateNameParams, ...props }) => {
  const [showSettingsDropdown, setShowSettingsDropdown] = React.useState(false);
  const [showRenameDialog, setShowRenameDialog] = React.useState(false);
  const [updatedName, setUpdatedName] = React.useState<string>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setShowSettingsDropdown(false);
    setAnchorEl(null);
  };

  const settings = updateNameParams
    ? (props.settings || []).concat([
        {
          id: "change-name-button",
          label: strings.pageUpdateNameSettingsButton,
          onClick: (): void => {
            setShowRenameDialog(true);
          },
        },
      ])
    : props.settings;

  return (
    <div className={["page-content--wide", "page-content"].join(" ")}>
      <Box display="flex" alignItems="flexEnd">
        <Box>
          <Typography variant="h4" data-testid="page-title">
            {props.title}
          </Typography>
        </Box>
        {settings && (
          <Box ml={1}>
            <IconButton
              onClick={(event): void => {
                handleClick(event);
                setShowSettingsDropdown(!showSettingsDropdown);
              }}
              data-testid="settings-button"
            >
              <SettingsIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={showSettingsDropdown}
              onClose={handleClose}
            >
              {settings.map((setting) => {
                return (
                  <MenuItem
                    key={setting.id}
                    data-testid={setting.id}
                    onClick={(): void => {
                      setting.onClick();
                      handleClose();
                    }}
                    component="label"
                  >
                    {setting.label}
                  </MenuItem>
                );
              })}
            </Menu>
          </Box>
        )}
      </Box>

      {props.children || null}
      {updateNameParams && (
        <UpdateTextDialog
          open={showRenameDialog}
          onClose={(): void => {
            setShowRenameDialog(false);
          }}
          title={strings.pageRenameSettingsButtonLabel}
          onSubmit={(): void => {
            if (updatedName !== undefined) {
              updateNameParams.updateName(updatedName || "", () => {
                setShowRenameDialog(false);
              });
            } else {
              console.error("Tried to update name but name was not set");
            }
          }}
          onTextChange={(name): void => {
            setUpdatedName(name);
          }}
          textFieldLabel={strings.pageUpdateNameTextFieldLabel}
          textFieldValue={
            updatedName !== undefined
              ? updatedName
              : updateNameParams.currentName !== undefined
              ? updateNameParams.currentName
              : props.title
          }
          submitActionLoading={props.isBusyRenaming}
        />
      )}
    </div>
  );
};

function mapStateToProps(state: StoreState, props: OwnProps): StateProps {
  const renamingRequestId = (props.updateNameParams || {}).requestId;
  return {
    isBusyRenaming: renamingRequestId === undefined ? false : isRequestLoading(state.apiRequests, renamingRequestId),
  };
}

export const ConnectedPage = Redux.connect(mapStateToProps, {})(withRouter(Page));

export default ConnectedPage;
