import { createReducer } from "typesafe-actions";
import { SubscriptionsState } from "types/store";
import { UPDATE_SUBSCRIPTION_LIST, UPDATE_SUBSCRIPTION, USER_LOGOUT } from "../../constants/index";
import { Subscription } from "store/models/subscriptions";

// For more details on the design of this reducer see docs/redux_store_design.md#resource_storage
export const initialState: SubscriptionsState = {
  items: {},
  lists: {},
};

export const reducer = createReducer(initialState)
  .handleAction(USER_LOGOUT, () => {
    return initialState;
  })
  .handleAction(UPDATE_SUBSCRIPTION_LIST, (state, action) => {
    return {
      ...state,
      lists: {
        ...state.lists,
        [action.payload.listId]: {
          items: action.payload.items.map((user) => user.id),
          fetchedAt: new Date(Date.now()),
        },
      },
      items: {
        ...state.items,
        ...action.payload.items.reduce(
          (acc, user) => ({ ...acc, [user.id]: user }),
          {} as Record<string, Subscription>,
        ),
      },
    };
  })
  .handleAction(UPDATE_SUBSCRIPTION, (state, action) => {
    return {
      ...state,
      items: {
        ...state.items,
        [action.payload.id]: action.payload,
      },
    };
  });

// Store accessors

export const getSubscription = (state: SubscriptionsState, subscriptionId: string): Subscription | void => {
  return state.items[subscriptionId];
};

export const getSubscriptions = (state: SubscriptionsState, listId: string): Subscription[] | void => {
  const list = state.lists[listId];
  if (list) {
    return list.items.map((itemId) => state.items[itemId]);
  }
};
