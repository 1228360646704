import { getCurrentSubscription } from "store/reducers/currentUser";
import { updateApiRequest } from "store/actions/apiRequests";
import * as t from "io-ts";
import { pipe } from "fp-ts/lib/pipeable";
import { fold } from "fp-ts/lib/Either";
import { reporter } from "io-ts-reporters";
import {
  ApiErrorsValidator,
  ApiErrors,
  ApiError,
  JSONParsingError,
  ApplicationError,
  ErrorModel,
} from "store/models/errors";
import {
  AllActions,
  StoreState,
  ApiRequestAction,
  UserLogoutAction,
  JSONified,
  ApiRequstCallbackAction,
} from "types/store";
import { Dispatch } from "redux";
import { userLogout } from "store/actions/currentUser";
import { env } from "./config";
import FileSaver from "file-saver";
import pako from "pako";

export type SortDirection = "asc" | "desc";

export interface ListQueryParams {
  offset?: number;
  limit?: number;
  sortBy?: string;
  orderBy?: SortDirection;
  query?: string;
}

export const API_HOST = env.apiHost;

export const getAuthToken = (): string | null => {
  return localStorage.getItem("token");
};

export const parseBody = (response: Response): Promise<Record<string, unknown> | undefined> => {
  if (response.status === 204) {
    // If the BE returns 204 then the browser will not pass the result body even if there was one provided by the BE
    return new Promise((resolve) => {
      resolve(undefined);
    });
  }
  if (response.headers.get("content-type")?.includes("application/json")) {
    return response.json();
  }
  return new Promise((resolve) => {
    resolve(undefined);
  });
};

export const mockRequest = (url: string, requestsToMock: [RegExp, () => Response][]): Promise<Response> | undefined => {
  let response: Response | undefined;
  requestsToMock.some((requestMatcher) => {
    if (requestMatcher[0].test(url)) {
      response = requestMatcher[1]();
      return true;
    }
    return false;
  });
  if (response) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(response);
      }, 1000);
    });
  }
};

export const validateJson = <T, O, I>(validator: t.Type<T, O, I>, input: I): Promise<T> => {
  const result = validator.decode(input);
  return pipe(
    result,
    fold(
      () => {
        const error: JSONParsingError = {
          key: "json_parsing_error",
          createdAt: new Date(Date.now()),
          metadata: { reasons: reporter(result) },
        };
        return Promise.reject(new ApplicationError(error));
      },
      (value: T) => Promise.resolve(value),
    ),
  );
};

export const validateJsonSync = <T, O, I>(validator: t.Type<T, O, I>, input: I): T => {
  const result = validator.decode(input);
  if (result._tag === "Left") {
    const error: JSONParsingError = {
      key: "json_parsing_error",
      createdAt: new Date(Date.now()),
      metadata: { reasons: reporter(result) },
    };
    throw new ApplicationError(error);
  } else {
    return result.right;
  }
};

export const generateRequestId = (requestName: string, resourceId?: string): string =>
  resourceId !== undefined ? `${requestName}_${resourceId}` : requestName;

export const checkStatus = (response: Response): Response | Promise<Response> => {
  if (response.status === 401) {
    throw new ApiError({ key: "authentication_error", createdAt: new Date(Date.now()), metadata: {} });
  } else if (response.status === 403) {
    return response
      .json()
      .then((json) => {
        // If an api request returns this error then it means that the user will need to login again
        // to accept the terms.
        if (json.key === "must_accept_terms") {
          throw new ApiError({ key: "authentication_error", createdAt: new Date(Date.now()), metadata: {} });
        }
        return validateJson(ApiErrorsValidator, json);
      })
      .then((error) => {
        throw error;
      })
      .catch((error) => {
        // If the error is not an Api Error force a generic error
        if (!(error instanceof ApiError)) {
          throw new ApiError({ key: "generic_error", createdAt: new Date(Date.now()), metadata: {} });
        }
        throw error;
      });
  } else if (response.status >= 400 && response.status < 500) {
    // For 4xx errors we assume the server is returning a useable error
    return response
      .json()
      .then((json) => {
        json.createdAt = new Date(Date.now()).toISOString();
        return validateJson(ApiErrorsValidator, json);
      })
      .then((validatedJson: ApiErrors) => {
        throw new ApiError(validatedJson);
      })
      .catch((error) => {
        // If the error is not an Api Error force a generic error
        if (!(error instanceof ApiError)) {
          throw new ApiError({ key: "generic_error", createdAt: new Date(Date.now()), metadata: {} });
        }
        throw error;
      });
  } else if (response.status >= 500) {
    // For 5xx errors we assume the server has raised an error it was not able to handle
    throw new ApiError({ key: "server_error", createdAt: new Date(Date.now()), metadata: {} });
  }
  return response;
};

export const generateApiError = (error: Error): ApiErrors => {
  const genericError: ApiErrors = { key: "generic_error", createdAt: new Date(Date.now()), metadata: {} };
  // If the Error is not a known error then just raise a generic error
  if (error instanceof ApiError || error instanceof ApplicationError) {
    return error.error;
  } else {
    return genericError;
  }
};

export const logoutUser = (dispatch: Dispatch<UserLogoutAction>): void => {
  dispatch(userLogout());
  localStorage.removeItem("token");
  window.location.href = "/login";
};

export const handleApiError = (error: ErrorModel, dispatch: Dispatch<AllActions>): void => {
  if (error.key === "authentication_error") {
    logoutUser(dispatch);
  }
};

export type ThunkResponse<Model> = (
  dispatch: Dispatch<ApiRequestAction<Model> | AllActions>,
  getState: () => StoreState,
) => void;

export const compressData = (content: Uint8Array | number[] | string): FormData => {
  const payload = new FormData();
  const compressedData = new Blob([pako.deflate(content)], {
    type: "application/octet-stream",
  });
  payload.append("payload", compressedData, "payload");
  return payload;
};

export interface UploadFileParams<Model> {
  urlPath: string;
  file: File;
  requestId: string;
  validator: t.Type<Model, JSONified<Model>>;
  onSuccess: ApiRequstCallbackAction<Model>;
}

export const uploadFile = <Model>(params: UploadFileParams<Model>) => (
  dispatch: Dispatch<AllActions>,
  getState: () => StoreState,
): void => {
  dispatch(updateApiRequest(params.requestId, { state: "loading" }));
  const currentSubscription = getCurrentSubscription(getState());
  if (currentSubscription) {
    params.file.arrayBuffer().then((content) => {
      const payload = compressData(new Uint8Array(content));
      fetch(`${API_HOST}/subscription/${currentSubscription.subscriptionId}/${params.urlPath}`, {
        method: "POST",
        body: payload,
        headers: {
          "Authorization": `Bearer ${getAuthToken()}`,
        },
      })
        .then(checkStatus)
        .then(parseBody)
        .then((body) => {
          if (params.validator && body) {
            const result = validateJson(params.validator, body);
            return result;
          }
        })
        .then((model) => {
          if (model) {
            params.onSuccess(model)(dispatch, getState);
            dispatch(updateApiRequest(params.requestId, { state: "success" }));
            return model;
          }
        })
        .catch((error) => {
          const apiError = generateApiError(error);
          handleApiError(apiError, dispatch);
          dispatch(
            updateApiRequest(params.requestId, {
              state: "error",
              meta: { error: apiError },
            }),
          );
        });
    });
  } else {
    console.log("Tried to make request that depends on an active subscription without subscription id. ");
    logoutUser(dispatch);
  }
};

export const downloadFile = (
  path: string,
  fileName: string,
  requestId: string,
  dispatch: Dispatch<AllActions>,
): Promise<Response | unknown> => {
  dispatch(updateApiRequest(requestId, { state: "loading" }));
  return new Promise((resolve) => {
    // This method wraps XMLHttpRequest because using fetch resulted in zip files being corrupted
    const xhr = new XMLHttpRequest();
    xhr.open("GET", `${API_HOST}${path}`, true);
    xhr.responseType = "blob";
    xhr.setRequestHeader("Authorization", `Bearer ${getAuthToken()}`);
    xhr.onreadystatechange = (): void => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        resolve(
          // Resolve a Response object so that the check status and error handling
          // logic can be resused
          new Promise<Response>((resolve) => {
            resolve(new Response(xhr.response, { status: xhr.status }));
          })
            .then(checkStatus)
            .then(() => {
              const blob = xhr.response;
              FileSaver.saveAs(blob, fileName);
              dispatch(updateApiRequest(requestId, { state: "success" }));
              resolve();
            })
            .catch((error) => {
              const apiError = generateApiError(error);
              handleApiError(apiError, dispatch);
              dispatch(
                updateApiRequest(requestId, {
                  state: "error",
                  meta: { error: apiError },
                }),
              );
            }),
        );
      }
    };
    xhr.send();
  });
};

export const withSubscriptionId = <Model>(requestFunc: (subId: string) => ApiRequestAction<Model>) => (
  dispatch: Dispatch<ApiRequestAction<Model> | UserLogoutAction>,
  getState: () => StoreState,
): ApiRequestAction<Model> | void => {
  const currentSubscription = getCurrentSubscription(getState());
  if (currentSubscription) {
    dispatch(requestFunc(currentSubscription.subscriptionId));
  } else {
    console.log("Tried to make request that depends on an active subscription without subscription id. ");
    logoutUser(dispatch);
  }
};
