import React from "react";
import IdleTimer from "react-idle-timer";
import { userLogout, deleteAuthTokens } from "../store/actions/currentUser";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "./navigation/NavBar";
import { env } from "utils/config";

interface ComponentProps {}
interface StoreProps {}
interface DispatchProps {
  userLogout: typeof userLogout;
  deleteAuthTokens: typeof deleteAuthTokens;
}
type Props = ComponentProps & DispatchProps & StoreProps & RouteComponentProps;

const InactivityHandler: React.FC<Props> = (props) => {
  const timeRef = React.useRef<IdleTimer>(null);
  React.useEffect(() => {
    const storageHandler = (event: StorageEvent): void => {
      if (event.storageArea === localStorage && event.key === "lastActiveTime") {
        timeRef.current?.reset();
      }
    };

    window.addEventListener("storage", storageHandler);

    return (): void => {
      window.removeEventListener("storage", storageHandler);
    };
  }, []);

  const onUserIdle = (): void => {
    logoutUser(props);
  };

  const onAction = (): void => {
    localStorage.setItem("lastActiveTime", `${Date.now()}`);
  };

  const onActive = (): void => {
    timeRef.current?.reset();
  };

  return (
    <>
      <IdleTimer
        ref={timeRef}
        element={document}
        onActive={onActive}
        stopOnIdle={true}
        onIdle={onUserIdle}
        onAction={onAction}
        debounce={250}
        timeout={Number(env.inactivityTimeOut)}
      />
    </>
  );
};

const mapStateToProps = (): StoreProps => {
  return {};
};

const dispatchProps: DispatchProps = {
  userLogout,
  deleteAuthTokens,
};

export default connect(mapStateToProps, dispatchProps)(withRouter(InactivityHandler));
