import {
  UPDATE_DATAMODEL_TITLES,
  UPDATE_SIMULATION_MODEL_SUMMARIES,
  UPDATE_SIMULATION_MODEL,
  UPDATE_SIMULATION_RESULT_SUMMARY_LIST,
  UPDATE_SIMULATION_RESULT,
  USER_LOGOUT,
  UPDATE_SIMULATION_MODEL_SUMMARY,
} from "../../constants/index";
import { createReducer } from "typesafe-actions";

export const initialState = {
  dataModelTitles: {},
};

export const reducer = createReducer(initialState)
  .handleAction(UPDATE_DATAMODEL_TITLES, (state, action) => {
    return {
      ...state,
      dataModelTitles: {
        ...state.dataModelTitles,
        [action.payload.id]: action.payload.title,
      },
    };
  })
  .handleAction(UPDATE_SIMULATION_MODEL_SUMMARIES, (state, action) => {
    return {
      ...state,
      dataModelTitles: action.payload.items.reduce((acc, cur) => {
        return { ...acc, [cur.id]: cur.name };
      }, state.dataModelTitles),
    };
  })
  .handleAction(UPDATE_SIMULATION_MODEL, (state, action) => {
    return {
      ...state,
      dataModelTitles: {
        ...state.dataModelTitles,
        [action.payload.id]: action.payload.name,
      },
    };
  })
  .handleAction(UPDATE_SIMULATION_MODEL_SUMMARY, (state, action) => {
    return {
      ...state,
      dataModelTitles: {
        ...state.dataModelTitles,
        [action.payload.id]: action.payload.name,
      },
    };
  })
  .handleAction(UPDATE_SIMULATION_RESULT_SUMMARY_LIST, (state, action) => {
    return {
      ...state,
      dataModelTitles: action.payload.items.reduce((acc, cur) => {
        return { ...acc, [cur.id]: cur.name };
      }, state.dataModelTitles),
    };
  })
  .handleAction(UPDATE_SIMULATION_RESULT, (state, action) => {
    return {
      ...state,
      dataModelTitles: {
        ...state.dataModelTitles,
        [action.payload.id]: action.payload.name,
      },
    };
  })
  .handleAction(USER_LOGOUT, () => {
    return initialState;
  });
