import React from "react";
import "styles/page.scss";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { strings } from "content";
import CircularProgress from "@material-ui/core/CircularProgress";

export interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  description?: string;
  onConfirm: () => void;
  confirmationActionLoading?: boolean;
}

const ConfirmationDialog: React.FC<Props> = (props) => {
  const { open, title, onClose, onConfirm, description, confirmationActionLoading } = props;
  return (
    <Dialog
      open={open}
      onClose={(): void => {
        if (!confirmationActionLoading) {
          onClose();
        }
      }}
      data-test-id="confirmation-modal"
    >
      <DialogTitle>{title}</DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button
          variant="outlined"
          disabled={confirmationActionLoading}
          onClick={onClose}
          color="primary"
          data-test-id="confirmation-modal-cancel-button"
        >
          {strings.confirmationDialogCancelButtonText}
        </Button>
        <Button
          variant="contained"
          onClick={onConfirm}
          color="primary"
          data-test-id="confirmation-modal-confirm-button"
          disabled={confirmationActionLoading}
          endIcon={confirmationActionLoading ? <CircularProgress color="inherit" size={20} /> : undefined}
        >
          {strings.confirmationDialogConfirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
