import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "./App.scss";
import "styles/base.scss";
import { generateRoutes } from "utils/navigation";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { ResponsiveProvider, useDeviceTypes } from "utils/responsive";
import { navItems, staticPages } from "routes";
import { theme } from "utils/theme";
import { SnackbarProvider } from "notistack";
import InactivityHandler from "components/InactivityHandler";
import Footer from "components/Footer";
import ScrollToTop from "components/ScrollToTop";
import PageLoader from "components/PageLoader";

const App: React.FC = () => {
  const deviceTypes = useDeviceTypes();

  return (
    <SnackbarProvider>
      <Router>
        <InactivityHandler />
        <ScrollToTop />
        <Suspense fallback={<PageLoader />}>
          <ResponsiveProvider value={deviceTypes}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <div className="appPage">
                <Footer>
                  <Switch>{generateRoutes(navItems, staticPages)}</Switch>
                </Footer>
              </div>
            </ThemeProvider>
          </ResponsiveProvider>
        </Suspense>
      </Router>
    </SnackbarProvider>
  );
};

export default App;
