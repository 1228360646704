import React, { ReactElement } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Route } from "react-router-dom";
import NavBar from "components/navigation/NavBar";
import ErrorHandler from "components/ErrorHandler";

interface NavigationRouteProps {
  component: React.ElementType<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
  path: string;
  navProps: NavItemProps;
  allNavItems: NavItem[];
}
/**
 * This component allows us to pass properites to the component rendered by
 * the Route as well as associating the page with navigation bar.
 */
export const NavigationRoute: React.FC<NavigationRouteProps> = ({ component: Component, allNavItems, ...navProps }) => {
  return (
    <Route
      exact
      {...navProps}
      render={(routeProps): ReactElement => {
        return (
          <div>
            <NavBar navItems={allNavItems} currentPage={navProps.navProps} />
            <ErrorHandler>
              <Component {...routeProps} />
            </ErrorHandler>
          </div>
        );
      }}
    />
  );
};

export interface PathItem {
  // The navigation item id
  id: string;
  // The name for the given page that will be displayed if there is no
  // underlying data model or if the underlying data model has no name
  defaultDisplayName: string;
  // Many pages have an underlying data model. Those models usually have a title.
  // The id for these dataModels are usually specified in the url path somewhere.
  // eg. /model/:model_id/results/:resultId. These placeholders get filled by actual
  // ids and the mapping of idKey: actualId is stored in the match property of the
  // router props. This attribute describes the idKey that should be used to get the id
  // so that the store can be queried for the page name if it exists
  dataModelIdKey?: string;
  // The path used to register this navigation item
  path: string;
}

export interface NavItemProps {
  pathItems: PathItem[];
  pathMeta: PathItem;
}

export interface NavItem {
  id: string;
  path: string;
  props: NavItemProps;
  component:
    | React.ComponentType<RouteComponentProps<any>> // eslint-disable-line @typescript-eslint/no-explicit-any
    | React.ComponentType<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface NavTreeItem extends PathItem {
  children: NavTreeItem[];
  props?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  component:
    | React.ComponentType<RouteComponentProps<any>> // eslint-disable-line @typescript-eslint/no-explicit-any
    | React.ComponentType<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface StaticPage {
  id: string;
  path: string;
  component:
    | React.ComponentType<RouteComponentProps<any>> // eslint-disable-line @typescript-eslint/no-explicit-any
    | React.ComponentType<any>; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export const navTreeFactory = (additionalNavItems: NavTreeItem[] = []): NavTreeItem[] => {
  const testComponent = (id: string): (() => ReactElement) => {
    const Component = (): ReactElement => <div>{id}</div>;
    return Component;
  };
  return [
    {
      path: "/models",
      id: "model",
      defaultDisplayName: "Models",
      component: testComponent("model_content"),
      children: [
        {
          path: "/models/:modelId",
          id: "nest",
          dataModelIdKey: "modelId",
          defaultDisplayName: "Model",
          component: testComponent("nest_content"),
          children: [
            {
              path: "/models/:modelId/run_simulation",
              id: "run_simulation",
              dataModelIdKey: "modelId",
              defaultDisplayName: "Run Simulation",
              component: testComponent("run_simulation_content"),
              children: [],
            },
          ],
        },
      ],
    },
    ...additionalNavItems,
  ];
};

/**
 * Takes a navigation tree with nested child pages and turns it into a flat list
 * with each nested item provided with embedded information about its lineage.
 */
export function buildNavTree(navTree: NavTreeItem[], parentNodes: PathItem[] = []): NavItem[] {
  const navItems = navTree.reduce((acc: NavItem[], cur: NavTreeItem) => {
    const pathItem: PathItem = {
      id: cur.id,
      defaultDisplayName: cur.defaultDisplayName,
      path: cur.path,
      dataModelIdKey: cur.dataModelIdKey,
    };
    const currentParentItems = parentNodes.concat(pathItem);
    const navBarProps = {
      pathItems: currentParentItems,
      pathMeta: pathItem,
    };
    const { children, defaultDisplayName, ...navItems } = cur; // eslint-disable-line @typescript-eslint/no-unused-vars
    const childItems = buildNavTree(cur.children || [], currentParentItems);
    const flattenedItems: NavItem[] = [
      {
        ...navItems,
        props: { ...cur.props, ...navBarProps },
      },
      ...childItems,
    ];
    return acc.concat(flattenedItems);
  }, [] as NavItem[]);

  return navItems;
}

export const generateRoutes = (items: NavItem[], staticPages: StaticPage[] = []): ReactElement[] => {
  const itemRoutes = items.reverse().map((navItem) => {
    return (
      <NavigationRoute
        navProps={navItem.props}
        key={navItem.id}
        path={navItem.path}
        allNavItems={items}
        component={navItem.component}
        {...navItem.props}
      />
    );
  });
  const staticRoutes = staticPages.map((page) => {
    return (
      <Route
        exact
        key={page.id}
        {...page}
        render={(routeProps): ReactElement => {
          return (
            <div>
              <page.component {...routeProps} />
            </div>
          );
        }}
      />
    );
  });

  return [...itemRoutes, ...staticRoutes];
};
