/// Descriptions which are displayed when the user clicks to find more about a field.
/// If you see something to be added or improved, Be Bold and make an edit!

// COMMON ** //

const NORMAL_TRANSITIONS =
  "These transitions describe where a loan will move to once the recovery state has completed and the loan has not defaulted.";

const COST_BALANCE_PERCENTAGE =
  "Costs calculated as a percentage of the opening balance of the state applied during every period that the balance is within the state.";

const COST_FIXED =
  "Fixed costs applied during every period, if the loan is in the state during the period – Please note, given the statistical approach taken by ACAP, the costFixed is also proportional to the portions of balances that have been processed. For example, a costFixed of £100 and a “flat” spreadType in conjunction with spreadTerm of 4 would see the costFixed being £100, £75, £50, and £25 for the 4 periods.";

const DEFAULTED_TRANSITIONS =
  "These transitions describe where a loan will move to once it defaults before the recovery state completes.";

const KEEP_RATIO =
  "Used in calculating the default rate. Keep ratio is 1 minus the sum of all principal and interest payments over the loan term divided by the balance due.";

const INTEREST_RATE = "Applicable interest rate over the life of the arrangement.";

const LAG = "Time taken from entering the state until first payment is realised.";

const DRAW_DOWN_RATE =
  "The % of the undrawnBalance to draw each period. When additional balance is drawn during the period, the payment amount is recalculated incorporating the new balance without adjusting the term. Drawdowns stop when the undrawnBalance is fully drawn. If the term is reached before 100% draw, then the remaining undrawnBalance is never drawn.";

// ** RESTRUCTURING ** //

const ACCEPTANCE_RATE = "Percentage of exposures that end up under terms of the arrangement.";

const HAIRCUT =
  "Percentage of the exposure to forgive. As scheduled principal payments are made the haircut portion of the principal payment is written off. When defaults occur, a portion of the haircut is reinstated before the exposure is passed to the following recovery state, as otherwise borrowers would be incentivised to default.";

const DEFAULT_RATE =
  "The monthly default rate. When a single number is specified, this rate is used until end of the term. Defaults are passed to the following state via the defaulted path in the Graph.";

const DEFAULT_RATE_ARRAY =
  "The monthly default rate. When a single number is specified, this rate is used until end of the term. Defaults are passed to the following state via the defaulted path in the Graph.";

const HAIRCUT_MAXIMUM =
  "Only used when the haircut field is not explicitly provided. The maximum haircut to apply. e.g. If the Target LTV is 100%, but the current one is 600%, a haircut of 0.83 is required. A haircut_maximum of 0.75 would prevent the full reduction, and result in LTV of 150%.";

const REV = "Real-estate value";

const SALES_PERIOD =
  "If desired, sales of sub-portfolios may be undertaken in pre-specified periods, valued using a fixed annual discount rate.\nThe sold balance may vary based upon the number of loans that have entered the state by the sale period, and any amortisation or balance reduction that has occurred until this point.\nWhere multiple sales are defined in a single state, these occur sequentially, and reference the unsold balance. i.e. the cashflows used to determine the value of a sale do not themselves include sale proceeds. \nSales that occur in upstream states include the relevant cashflows that would be generated should a loan transition to another state after the sale date.";

const TARGET_LTV =
  "Target loan-to-value ratio used to determine the haircut. For each exposure a unique haircut is calculated based upon the balance and real-estate value in order to achieve a given LTV ratio.";

const LIEN_AMOUNT_HAIRCUT =
  "The discounts to the lienAmount used when calculating the CREV which discounts the entire lien (note that the lienAmountHaircut must be the same for a single security item)";

const CLAIM_AMOUNT_HAIRCUT =
  "The discount to the lienAmount that can be applied to a single collateral item used when calculating the CREV which discounts only the relationship between one collateral item and one loan.";

const TERM = "Remaining term of the arrangement in months.";

const AMORTISATION_TYPE = "How the loan should amortise: 'French', 'Interest Only'.";

const AMORTISING_PERCENTAGE =
  "What portion of the balance should follow a French amortisation schedule. The remaining portion is considered Interest Only, with a Bullet repayment upon maturity.";

const COST_COLLECTIONS_PERCENTAGE =
  "Costs calculated as a percentage of the collection in the state applied during every period, after accounting for costFixed.  Note that costs from costBalancePercentage are excluded from the net collection calculation.";

const COST_POST_HAIRCUT_BALANCE_PERCENTAGE =
  "Costs calculated as a percentage of the accepted restructured balance (i.e. 1 minus the haircut amount), applied once at the point of restructure.";

// ** INITIAL ** //

const SPREAD_TYPE =
  "Profile type of how the initial balance should feed into Graph States. Currently only a single value across all `initial_state_keys` is accepted. \n\nOptions: \n'Flat' - Equally spread entrance across X periods.";

const SPREAD_TERM =
  "The number of period (months) over which the balance of the loans will move from the initial state to other subsequent states. Please note, this shall be understood from a statistical point of view. For example, a spreadTerm of 5 with a ‘flat’ spreadType means the loan has equal probability of getting processed in any of the following 5 periods.";

const SPREAD_LAG =
  "The delay to apply in periods (months) until the balance of the loans start to be moved from the initial state (where no recoveries are being made) to other subsequent states such as restructuring states (where recoveries are generated).";

// ** CUSTOM AMORTISATION ** //

const CURVE =
  "The unit-relative recovery curve, specified as a string of comma-separted floats. This format is useful when ingesting csvs.";

const CURVE_ARRAY =
  "The unit-relative recovery curve, applied to any balance that enters the state. All recoveries are considered as principal reductions.";

const SKIP_PERIODS = "Skip X periods from start of the curve.";

const END_PERIOD = "Curve period after which collections are not considered.";

const INTEREST_RATE_CURVE = "An interest rate curve expressed as a comma-separated string of values.";

const INTEREST_RATE_ARRAY =
  "Applicable interest rate over the life of the arrangement Payment Interval: Monthly, Daycount Fraction: 30/360";

// ** COURT DECISIION ** //

const COURT_DECISION_TIME =
  "The number of periods (in months) from balance entry to the Graph until court decision. N.B. where `spread_term` is non-zero, this will be adjusted by the appropriate lag.";

const PERIODIC_PAYMENTS_RELATIVE =
  "Until `court_decision_time`, the periodic payment made, relative to the loan initial balance.";

const PRE_DEFAULT_RATE_ARRAY =
  "Until `court_decision_time`, the periodic default rate. When determined from a Keep Ratio, this is determined differently to in other states, and uses a simple calculation of: (1-Keep Rate)/Time to court. We use this method as the court_decision_time is not related to the original loan term or payment amount.";

const POSITIVE_RULING_PROBABILITY =
  "Once `court_decision_time` periods have occurred since entry to the State, the proportion of Borrowers (which is then implied to the Loan also) that are accepted to the Post 3869 programme.";

const PERIODIC_PAYMENTS = "Until `court_decision_time`, the periodic payment made.";

const ORIGINAL_TERM = "Original Term of the Loan in months used to determine the interim payment amount.";

const ORIGINAL_INTEREST_RATE =
  "Original Interest Rate of the Loan per annum. Fixed. Used to determine the interim payment amount.";

const ORIGINAL_PAYMENT_PERCENTAGE =
  "For the interim payment amount, what fraction of the original periodic_payments should be used for the interim period.";

const PRE_DEFAULT_RATE =
  "Until `pre_law_3869_court_decision_time`, the periodic default rate. When determined from a Keep Ratio, this is determined differently to in other states, and uses a simple calculation of: (1-Keep Rate)/Time to court. We use this method as the court_decision_time is not related to the original loan term or payment amount.";

// ** DISPOSAL ** //

// const CREV = "Capped real-estate value";

// const CREV_OVER_BALANCE_CURVE_ARRAY =
//  "An array of floats that indicate the crev/balance figure at a given period in time.";

// const CREV_ADJUSTMENT_KEYS =
//  "A comma-separated array that will form the keys of the dictionary that is used to look up what the impact of a given increase or decrease on the book value of the loan has on the crev/balance value.";

const HPI_CURVE = "A comma separated array representing the house price indexation factor over time.";

// const CREV_AJUSTMENT_VALUES =
//  "A comma-separated array that will form the values of the dictionary that is used to look up what the impact of a given increase or decrease on the book value of the loan has on the crev/balance value.";

const REV_HAIRCUT = "A haircut that is applied on the estimated value of a property for a given period.";

// const CREV_OVER_BALANCE =
//  "Ratio of allocated CREV/balance. For disposals via DFAS or Liquidation, this figure is multiplied by the balance in this state to determine the proceed.";

const LAG_ONBOARDING =
  "Delay in number of periods (months) to apply from the point at which the loans enters the state until the point at which the collateral litem is onboard.";

const LAG_SALE =
  "Delay in number of periods (months) to apply from the point at which the collateral item is onboard until the point at which the collateral item is sold.";

const LAG_PROCEEDS_RECEIVED =
  "Delay in number of periods (months) to apply from the point at which the collateral item is sold until the proceeds from the sale are received.";

const COST_PRE_ONBOARD_FIXED =
  "A fixed fee paid for the collateral during the lagOnboarding period. Fee is paid by the portfolio owner and not split with 3rd party claimants.";

const COST_PRE_ONBOARD_REV_PERCENTAGE =
  "A fee calculated as a percentage of the collateralValue paid for the collateral during the lagOnboarding period. Fee is paid by the portfolio owner and not split with 3rd party claimants.";

const COST_ONBOARDING_FIXED = "A fixed fee paid for the collateral at the point of onboarding.";

const COST_ONBOARDING_REV_PERCENTAGE =
  "A fee calculated as a percentage of the collateralValue paid for the collateral at the point of becoming onboard.";

const COST_ONBOARD_FIXED =
  "A fixed fee paid for the collateral during the lagSale period. Fee is paid by the portfolio owner and not split with 3rd party claimants.";

const COST_ONBOARD_REV_PERCENTAGE =
  "A fee calculated as a percentage of the collateralValue paid for the collateral during the lagSale period. Fee is paid by the portfolio owner and not split with 3rd party claimants.";

const COST_SALE_FIXED = "A fixed fee paid for the collateral at the point of sale.";

const COST_SALE_REV_PERCENTAGE =
  "A fee calculated as a percentage of the collateralValue paid for the collateral at the point of sale.";

// ** ANALYTICALBP ** //

const BESPOKE_COSTS =
  "Fixed costs involved in recovering cashflows.  Entered by way of a comma delimited string representing a cost amount per period, e.g. 1500,1200,1100,1050.";

const GROSS_CASHFLOWS =
  "The expected gross cash flows. Entered by way of a comma delimited string representing the amount per period, e.g. 1500,1200,-1100,1050. The grossCashflows may be negative to present a net draw down. A  positive difference between gossCashflows and pricipalCashflows is assumed to represent interest payments.";

const PRINCIPAL_CASHFLOWS =
  "The expected principal cash flows. Entered by way of a comma delimited string representing the amount per period, e.g. 1500,1200,-1100,1050. The principalCashflows may be negative to present a net draw down. ";

const STRESS_FACTOR =
  "The percentage of the each of bespokeCosts, grossCashflows and principalCashflows that should be haircut. ";

// ** Code bits ** //

const STATE_TYPES = [
  "Initial",
  "Restructuring",
  "CustomAmortisation",
  "Disposal",
  "AnalyticBP",
  "PreLaw3869",
  "CourtDecision",
  "LegalRestructuring",
  "UncollectedBalances",
  "WriteOff",
];

const configTableInfoText = {
  // Common fields
  ...STATE_TYPES.reduce<Record<string, string>>((acc, cur) => {
    return {
      ...acc,
      [`paramDescription_${cur}_normalTransitions`]: NORMAL_TRANSITIONS,
      [`paramDescription_${cur}_defaultedTransitions`]: DEFAULTED_TRANSITIONS,
      [`paramDescription_${cur}_salesPeriods`]: SALES_PERIOD,
      [`paramDescription_${cur}_lag`]: LAG,
      [`paramDescription_${cur}_keepRatio`]: KEEP_RATIO,
      [`paramDescription_${cur}_interestRate`]: INTEREST_RATE,
      [`paramDescription_${cur}_costBalancePercentage`]: COST_BALANCE_PERCENTAGE,
      [`paramDescription_${cur}_costFixed`]: COST_FIXED,
    };
  }, {}),
  ...["Restructuring", "LegalRestructuring", "Disposal"].reduce<Record<string, string>>((acc, cur) => {
    return {
      ...acc,
      [`paramDescription_${cur}_lienAmountHaircut`]: LIEN_AMOUNT_HAIRCUT,
      [`paramDescription_${cur}_claimAmountHaircut`]: CLAIM_AMOUNT_HAIRCUT,
    };
  }, {}),

  // Initial fields
  "paramDescription_Initial_spreadType": SPREAD_TYPE,
  "paramDescription_Initial_spreadTerm": SPREAD_TERM,
  "paramDescription_Initial_spreadLag": SPREAD_LAG,

  // Custom Amortisation fields
  "paramDescription_CustomAmortisation_curve": CURVE,
  "paramDescription_CustomAmortisation_curveArray": CURVE_ARRAY,
  "paramDescription_CustomAmortisation_skipPeriods": SKIP_PERIODS,
  "paramDescription_CustomAmortisation_endPeriod": END_PERIOD,
  "paramDescription_CustomAmortisation_interestRateArray": INTEREST_RATE_ARRAY,
  "paramDescription_CustomAmortisation_interestRateCurve": INTEREST_RATE_CURVE,
  "paramDescription_CustomAmortisation_costCollectionsPercentage": COST_COLLECTIONS_PERCENTAGE,
  "paramDescription_CustomAmortisation_costPostHaircutBalancePercentage": COST_POST_HAIRCUT_BALANCE_PERCENTAGE,

  // Disposal fields
  //  "paramDescription_Disposal_crev": CREV,
  //  "paramDescription_Disposal_crevOverBalanceCurveArray": CREV_OVER_BALANCE_CURVE_ARRAY,
  //  "paramDescription_Disposal_crevAdjustmentsKeys": CREV_ADJUSTMENT_KEYS,
  //  "paramDescription_Disposal_crevAdjustmentsValues": CREV_AJUSTMENT_VALUES,
  "paramDescription_Disposal_hpiCurve": HPI_CURVE,
  "paramDescription_Disposal_revHaircut": REV_HAIRCUT,
  //  "paramDescription_Disposal_crevOverBalance": CREV_OVER_BALANCE,
  "paramDescription_Disposal_lagOnboarding": LAG_ONBOARDING,
  "paramDescription_Disposal_lagSale": LAG_SALE,
  "paramDescription_Disposal_lagProceedsReceived": LAG_PROCEEDS_RECEIVED,
  "paramDescription_Disposal_costPreOnboardFixed": COST_PRE_ONBOARD_FIXED,
  "paramDescription_Disposal_costPreOnboardRevPercentage": COST_PRE_ONBOARD_REV_PERCENTAGE,
  "paramDescription_Disposal_costOnboardingFixed": COST_ONBOARDING_FIXED,
  "paramDescription_Disposal_costOnboardingRevPercentage": COST_ONBOARDING_REV_PERCENTAGE,
  "paramDescription_Disposal_costOnboardFixed": COST_ONBOARD_FIXED,
  "paramDescription_Disposal_costOnboardRevPercentage": COST_ONBOARD_REV_PERCENTAGE,
  "paramDescription_Disposal_costSaleFixed": COST_SALE_FIXED,
  "paramDescription_Disposal_costSaleRevPercentage": COST_SALE_REV_PERCENTAGE,

  // AnalyticBP fields
  "paramDescription_AnalyticBP_costCollectionsPercentage": COST_COLLECTIONS_PERCENTAGE,
  "paramDescription_AnalyticBP_bespokeCosts": BESPOKE_COSTS,
  "paramDescription_AnalyticBP_grossCashflows": GROSS_CASHFLOWS,
  "paramDescription_AnalyticBP_principalCashflows": PRINCIPAL_CASHFLOWS,
  "paramDescription_AnalyticBP_stressFactor": STRESS_FACTOR,

  // Court Decision Fields
  ...["PreLaw3869", "CourtDecision", "LegalRestructuring"].reduce((acc, cur) => {
    return {
      ...acc,
      [`paramDescription_${cur}_courtDecisionTime`]: COURT_DECISION_TIME,
      [`paramDescription_${cur}_periodicPaymentsRelative`]: PERIODIC_PAYMENTS_RELATIVE,
      [`paramDescription_${cur}_preDefaultRateArray`]: PRE_DEFAULT_RATE_ARRAY,
      [`paramDescription_${cur}_positiveRulingProbability`]: POSITIVE_RULING_PROBABILITY,
      [`paramDescription_${cur}_periodicPayments`]: PERIODIC_PAYMENTS,
      [`paramDescription_${cur}_originalTerm`]: ORIGINAL_TERM,
      [`paramDescription_${cur}_originalInterestRate`]: ORIGINAL_INTEREST_RATE,
      [`paramDescription_${cur}_originalPaymentPercentage`]: ORIGINAL_PAYMENT_PERCENTAGE,
      [`paramDescription_${cur}_preDefaultRate`]: PRE_DEFAULT_RATE,
    };
  }, {}),

  // Amortising state fields
  ...["Restructuring", "LegalRestructuring", "CustomAmortisation"].reduce((acc, cur) => {
    return {
      ...acc,
      [`paramDescription_${cur}_acceptanceRate`]: ACCEPTANCE_RATE,
      [`paramDescription_${cur}_defaultRate`]: DEFAULT_RATE,
      [`paramDescription_${cur}_defaultRateArray`]: DEFAULT_RATE_ARRAY,
      [`paramDescription_${cur}_haircut`]: HAIRCUT,
      [`paramDescription_${cur}_haircutMaximum`]: HAIRCUT_MAXIMUM,
      [`paramDescription_${cur}_rev`]: REV,
      [`paramDescription_${cur}_targetLtv`]: TARGET_LTV,
    };
  }, {}),

  // Restructuring states
  ...["Restructuring", "LegalRestructuring"].reduce((acc, cur) => {
    return {
      ...acc,
      [`paramDescription_${cur}_term`]: TERM,
      [`paramDescription_${cur}_amortisationType`]: AMORTISATION_TYPE,
      [`paramDescription_${cur}_amortisingPercentage`]: AMORTISING_PERCENTAGE,
      [`paramDescription_${cur}_costCollectionsPercentage`]: COST_COLLECTIONS_PERCENTAGE,
      [`paramDescription_${cur}_drawDownRate`]: DRAW_DOWN_RATE,
    };
  }, {}),
};

export default configTableInfoText;
