import * as t from "io-ts";
import { DateType } from "utils/validatorTypes";

// Api Errors
export const ErrorMetaData = t.type({
  metadata: t.partial({
    message: t.string,
    reasons: t.array(t.string),
  }),
});

export const UnknownApiErrorValidator = t.intersection([
  t.type({
    key: t.string,
    createdAt: DateType,
  }),
  ErrorMetaData,
]);
export type UnknownApiError = t.TypeOf<typeof UnknownApiErrorValidator>;

export const SimpleErrorsValidator = t.intersection([
  t.type({
    key: t.union([
      t.literal("generic_error"),
      t.literal("cant_exceed_max_number_of_seats"),
      t.literal("server_error"),
      t.literal("invalid_password_strength"),
      t.literal("invitation_token_invalid"),
      t.literal("invitation_token_expired"),
      t.literal("incorrect_login_details"),
      t.literal("failed_validation"),
      t.literal("invite_not_found"),
      t.literal("model_not_found"),
      t.literal("models_not_found"),
      t.literal("simulation_not_found"),
      t.literal("simulation_result_not_found"),
      t.literal("state_id_not_found"),
      t.literal("account_locked"),
      t.literal("bad_data"),
      t.literal("bad_email"),
      t.literal("execution_failed"),
      t.literal("cannot_send_invite_email_to_user"),
      t.literal("invalid_password_strength"),
      t.literal("invitation_token_expired"),
      t.literal("missing_parameter"),
      t.literal("not_authenticated"),
      t.literal("non_alantra_admin"),
      t.literal("must_accept_terms"),
      t.literal("unsupported_token_type"),
      t.literal("team_not_found"),
      t.literal("user_not_found"),
      t.literal("users_not_found"),
      t.literal("no_password_set"),
      t.literal("not_authenticated"),
      t.literal("not_authorized"),
      t.literal("no workers"),
      t.literal("model_name_exists"),
      t.literal("subscription_not_found"),
      t.literal("role_not_found"),
      t.literal("insufficient_permissions"),
      t.literal("invalid_subscription_for_user"),
      t.literal("no_seats_remaining"),
      t.literal("validation_error"),
    ]),
    createdAt: DateType,
  }),
  ErrorMetaData,
]);

export const AuthenticationApiErrorValidator = t.intersection([
  t.type({
    key: t.literal("authentication_error"),
    createdAt: DateType,
  }),
  ErrorMetaData,
]);

export const ApiErrorsValidator = t.union([AuthenticationApiErrorValidator, SimpleErrorsValidator]);

export type ApiErrors = t.TypeOf<typeof ApiErrorsValidator>;

// Application Errors
export interface ErrorMetadata {
  metadata: {
    reasons: string[];
    message?: string;
  };
}

export interface JSONParsingError extends ErrorMetadata {
  key: "json_parsing_error";
  createdAt: Date;
}

export interface TableDoesNotMatchConfigurationError extends ErrorMetadata {
  key: "table_does_not_match_configuration";
  createdAt: Date;
}

export interface GenericApplicationError extends ErrorMetadata {
  key:
    | "generic_application_error"
    | "invalid_transformation_key_file"
    | "create_model_form_incomplete"
    | "missingSalesDiscountsForNode"
    | "periodsAndDiscountsDontMatch";
  createdAt: Date;
}

export type ApplicationErrors = JSONParsingError | TableDoesNotMatchConfigurationError | GenericApplicationError;

// All Errors

export type ErrorModel = ApiErrors | ApplicationErrors;

// Javascript Error Objects
const generateErrorMessage = (error: ErrorModel): string => {
  switch (error.key) {
    default: {
      return error.key;
    }
    case "json_parsing_error": {
      return error.metadata.reasons.join(", ");
    }
  }
};

export const generateApplicationError = (error: Omit<ApplicationErrors, "createdAt">): ApplicationErrors => {
  const now = new Date(Date.now());
  return { ...error, createdAt: now };
};

export class ApiError extends Error {
  error: ApiErrors;

  constructor(error: ApiErrors) {
    super(generateErrorMessage(error));
    this.error = error;
  }
}

export class ApplicationError<T extends ErrorModel> extends Error {
  error: T;

  constructor(error: T) {
    super(error.key);
    this.error = error;
  }
}
