import { createMuiTheme } from "@material-ui/core";

export const ALANTRA_COLORS = {
  "dark-green": "#274912",
  "teal": "#31A474",
  "mid-green": "#78C630",
  "light-green": "#C2E699",
  "red": "#F03B20",
  "orange": "#EB9532",
  "dark-grey": "#636363",
  "grey": "#BDBDBD",
  "purple": "#8856A7",
  "blue": "#2C7FE8",
};

export const ALANTRA_COLORS_VALUES = Object.values(ALANTRA_COLORS);

export const theme = createMuiTheme({
  typography: {
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  },
  palette: {
    primary: {
      main: "#274912",
    },
    secondary: {
      main: "#4d1300",
    },
    background: {
      paper: "#fff",
      default: "#F9F9FC",
    },
  },
});
