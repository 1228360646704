/* eslint-disable @typescript-eslint/no-unused-vars */
import { authToken } from "./mocks/users";
import { adminUser, normalUser } from "./mocks/users";
import { userList } from "./mocks/users";
import { subscriptionsList } from "./mocks/subscriptions";
import { simulationModel, simulationModelV1 } from "store/middleware/mocks/simulationModel";
import { simulationModelSummaries } from "store/middleware/mocks/simulationModelSummaries";
import { simulationResult, simulationResultV1 } from "store/middleware/mocks/simulationResults";
import { simulationResultSummaries } from "store/middleware/mocks/simulationResultSummaries";
import { teamList } from "store/middleware/mocks/teams";
import { fullStateParams } from "store/middleware/mocks/stateParams";
import { invite } from "./mocks/invites";

const mockedRequests: [RegExp, () => Response][] = [
  // [
  //   /model\/temp_config$/,
  //   (): Response =>
  //     new Response(JSON.stringify(simulationModel), { status: 200, headers: { "Content-Type": "application/json" } }),
  // ],
  // [
  //   /model\/temp_config_v1$/,
  //   (): Response =>
  //     new Response(JSON.stringify(simulationModelV1), { status: 200, headers: { "Content-Type": "application/json" } }),
  // ],
  // [/models$/, (): Response => new Response(JSON.stringify(simulationModelSummaries), { status: 200, headers: { "Content-Type": "application/json" }  })],
  // [/models\?/, (): Response => new Response(JSON.stringify(simulationModelSummaries), { status: 200, headers: { "Content-Type": "application/json" }  })],
  // [/users\?/, (): Response => new Response(JSON.stringify(userList), { status: 200, headers: { "Content-Type": "application/json" }  })],
  // [
  //   /models\/temp_config\/simulation_results$/,
  //   (): Response => new Response(JSON.stringify(simulationResultSummaries), { status: 200, headers: { "Content-Type": "application/json" }  }),
  // ],
  // [
  //   /.model\/temp_config\/simulation_results\/execute/,
  //   (): Response => new Response(JSON.stringify({ ...simulationResult, name: null }), { status: 200, headers: { "Content-Type": "application/json" }  }),
  // ],
  // [
  //   /model\/temp_config\/simulation_result\/someId$/,
  //   (): Response =>
  //     new Response(JSON.stringify(simulationResult), { status: 200, headers: { "Content-Type": "application/json" } }),
  // ],
  // [
  //   /model\/temp_config_v1\/simulation_result\/someId_v1$/,
  //   (): Response =>
  //     new Response(JSON.stringify(simulationResultV1), {
  //       status: 200,
  //       headers: { "Content-Type": "application/json" },
  //     }),
  // ],
  // [
  //   /.models\/temp_config\/simulation_results\/execute/,
  //   (): Response => new Response(JSON.stringify({ ...simulationResult, name: null }), { status: 200, headers: { "Content-Type": "application/json" }  }),
  // ],
  // [/team\/team_id_1$/, (): Response => new Response(JSON.stringify(teamList[0]), { status: 200, headers: { "Content-Type": "application/json" }  })],
  // [/team\/team_id_1\/models$/, (): Response => new Response(JSON.stringify(simulationModelSummaries), { status: 200, headers: { "Content-Type": "application/json" }  })],
  // [/team\/team_id_1\/users/, (): Response => new Response(JSON.stringify(userList), { status: 200, headers: { "Content-Type": "application/json" }  })],
  // [/teams$/, (): Response => new Response(JSON.stringify(teamList), { status: 200, headers: { "Content-Type": "application/json" }  })],
  // [/users$/, (): Response => new Response(JSON.stringify(userList), { status: 200, headers: { "Content-Type": "application/json" }  })],
  // [/user\/unique_admin_user_id/, (): Response => new Response(JSON.stringify(adminUser), { status: 200, headers: { "Content-Type": "application/json" }  })],
  // [/user\/unique_normal_user_id/, (): Response => new Response(JSON.stringify(normalUser), { status: 200, headers: { "Content-Type": "application/json" }  })],
  // [
  //   /state_params/,
  //   (): Response =>
  //     new Response(JSON.stringify(fullStateParams), { status: 200, headers: { "Content-Type": "application/json" } }),
  // ],
  // [/invites/, (): Response => new Response(JSON.stringify(invite), { status: 200, headers: { "Content-Type": "application/json" }  })],
  // [/users\/set_password/, (): Response => new Response(JSON.stringify(adminUser), { status: 200, headers: { "Content-Type": "application/json" }  })],
  // [/users\/login/, (): Response => new Response(JSON.stringify(authToken), { status: 200, headers: { "Content-Type": "application/json" }  })],
  // [/me$/, (): Response => new Response(JSON.stringify(adminUser), { status: 200, headers: { "Content-Type": "application/json" }  })],
  // [/subscriptions$/, (): Response => new Response(JSON.stringify(subscriptionsList), { status: 200, headers: { "Content-Type": "application/json" }  })],
];

export default mockedRequests;
