import { UPDATE_SIMULATION_MODEL_SUMMARIES, USER_LOGOUT, UPDATE_SIMULATION_MODEL_SUMMARY } from "../../constants/index";
import { AllActions, StoreState } from "types/store";
import { SimulationModelSummariesState } from "types/store";
import { SimulationModelSummary } from "store/models/simulationModel";
import { createReducer } from "typesafe-actions";

// For more details on the design of this reducer see docs/redux_store_design.md#resource_storage
export const initialState: SimulationModelSummariesState = {
  items: {},
  lists: {},
};

declare module "typesafe-actions" {
  interface Types {
    RootAction: AllActions;
  }
}

export const reducer = createReducer(initialState)
  .handleAction(USER_LOGOUT, () => {
    return initialState;
  })
  .handleAction(UPDATE_SIMULATION_MODEL_SUMMARY, (state, action) => {
    return {
      ...state,
      items: {
        ...state.items,
        [action.payload.id]: action.payload,
      },
    };
  })
  .handleAction(UPDATE_SIMULATION_MODEL_SUMMARIES, (state, action) => {
    return {
      ...state,
      lists: {
        ...state.lists,
        [action.payload.listId]: {
          items: action.payload.items.map((model) => model.id),
          fetchedAt: new Date(Date.now()),
        },
      },
      items: {
        ...state.items,
        ...action.payload.items.reduce(
          (acc, model) => ({ ...acc, [model.id]: model }),
          {} as Record<string, SimulationModelSummary>,
        ),
      },
    };
  });

export const getSimulationModelSummary = (state: StoreState, modelId: string): SimulationModelSummary =>
  state.simulationModelSummaries.items[modelId];

export const getSimulationModelSummaries = (
  state: SimulationModelSummariesState,
  listId: string,
): SimulationModelSummary[] => (state.lists[listId] ? state.lists[listId].items.map((id) => state.items[id]) : []);

export const searchSimulationModelSummaries = (
  state: SimulationModelSummariesState,
  query: string,
  limit: number,
): SimulationModelSummary[] =>
  Object.values(state.items)
    .filter((model) => model.name.toLocaleLowerCase().startsWith(query.toLocaleLowerCase()))
    .slice(0, limit);
