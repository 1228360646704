import { enrichRoles } from "./../models/users";
import { createReducer } from "typesafe-actions";
import { CurrentUserState, StoreState } from "types/store";
import {
  USER_LOGOUT,
  SET_CURRENT_USER,
  DELETE_USER,
  UPDATE_USER,
  UPDATE_SUBSCRIPTION_LIST,
  SET_ACTIVE_SUBSCRIPTION_USER,
} from "../../constants/index";
import { User, Role } from "store/models/users";

export const initialState: CurrentUserState = {};

export const reducer = createReducer(initialState)
  .handleAction(USER_LOGOUT, () => {
    return initialState;
  })
  .handleAction(SET_CURRENT_USER, (state, action) => {
    const updatedActiveSubscription = action.payload.roles.filter(
      (role) => role.id === state.activeSubscription?.id,
    )[0];
    return {
      ...state,
      user: action.payload,
      activeSubscription: updatedActiveSubscription
        ? { ...state.activeSubscription, ...updatedActiveSubscription }
        : action.payload.roles[0],
    };
  })
  // The following actions come from User reducer but should update the
  // current user reducer where appropriate
  .handleAction(DELETE_USER, (state, action) => {
    if (state.user && state.user.id === action.payload) {
      return { ...state, user: undefined, activeSubscription: undefined };
    }
    return { ...state };
  })
  .handleAction(UPDATE_USER, (state, action) => {
    if (state.user !== undefined && state.user.id === action.payload.id) {
      return {
        ...state,
        user: {
          ...state.user,
          name: action.payload.name,
          email: action.payload.email,
        },
      };
    }
    return { ...state };
  })
  .handleAction(SET_ACTIVE_SUBSCRIPTION_USER, (state, action) => {
    return {
      ...state,
      activeSubscription: action.payload,
    };
  })
  .handleAction(UPDATE_SUBSCRIPTION_LIST, (state, action) => {
    const { user, activeSubscription } = state;
    if (user && activeSubscription) {
      return {
        ...state,
        user: {
          ...user,
          roles: enrichRoles(user.roles, action.payload.items),
        },
        activeSubscription: enrichRoles([activeSubscription], action.payload.items)[0],
      };
    }
    return state;
  });

// Store accessors

export const getCurrentUser = (state: StoreState): User | void => {
  return state.currentUser.user;
};

export const getCurrentSubscription = (state: StoreState): Role | void => {
  return state.currentUser.activeSubscription;
};
