import { getAuthToken, generateApiError, handleApiError } from "./../../utils/api";
import { mockRequest, checkStatus, parseBody, validateJson, API_HOST } from "utils/api";
import { Dispatch, Middleware } from "redux";
import { API_REQUEST } from "../../constants";
import { StoreState, ApiRequestAction, AllActions } from "types/store";
import { updateApiRequest } from "store/actions/apiRequests";
import pako from "pako";

export const apiMiddleware = (
  requestsToMock: [RegExp, () => Response][] | never[] = [],
): Middleware<{}, StoreState, Dispatch> => (api) => (next) => (action: AllActions): unknown => {
  next(action);
  if (!action.type || action.type !== API_REQUEST) {
    return;
  }
  const apiRequestAction = action as ApiRequestAction<unknown>;

  const { meta } = apiRequestAction;
  const { dispatch, getState } = api;

  let response: undefined | Promise<Response>;

  dispatch(updateApiRequest(meta.id, { state: "loading" }));

  if (["test", "development"].includes(process.env.NODE_ENV)) {
    response = mockRequest(meta.url, requestsToMock);
  }
  let payload;
  if (meta.body) {
    if (meta.compress) {
      const jsonBody = JSON.stringify(meta.body);
      payload = new FormData();
      const compressedData = new Blob([pako.deflate(jsonBody)], {
        type: "application/octet-stream",
      });
      payload.append("payload", compressedData, "payload");
    } else {
      payload = JSON.stringify(meta.body);
    }
  }
  if (response === undefined) {
    response = fetch(`${API_HOST}${meta.url}`, {
      method: meta.method || "GET",
      body: payload,
      headers: {
        "Authorization": `Bearer ${getAuthToken()}`,
        ...(meta.compress ? {} : { "Content-Type": "application/json" }),
        ...meta.headers,
      },
    });
  }
  return response
    .then(checkStatus)
    .then(parseBody)
    .then((body) => {
      if (meta.validator && body) {
        const result = validateJson(meta.validator, body);
        return result;
      }
    })
    .then((model) => {
      if (meta.onSuccessAction) {
        meta.onSuccessAction(model)(dispatch, getState);
      }
      dispatch(updateApiRequest(meta.id, { state: "success" }));
      return model;
    })
    .catch((error) => {
      // console.log("BAD", error, error.error.metadata);

      console.error(error.message);
      const apiError = generateApiError(error);

      dispatch(updateApiRequest(meta.id, { state: "error", meta: { error: apiError } }));
      if (meta.onErrorAction) {
        console.log(meta.onErrorAction);
        meta.onErrorAction(apiError)(dispatch, getState);
      }
      handleApiError(apiError, dispatch);
    });
};

export default apiMiddleware;
