import React, { ReactElement } from "react";
import { NavItem, NavItemProps } from "utils/navigation";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {
  userLogout,
  deleteAuthTokens,
  DELETE_AUTH_TOKENS_REQUEST_ID,
  setActiveSubscription,
} from "store/actions/currentUser";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import NavBreadcrumbs from "./NavBreadcrumbs";
import Menu from "@material-ui/core/Menu";
import { ListItem, ListItemText, Drawer } from "@material-ui/core";
import { connect } from "react-redux";
import { StoreState } from "types/store";
import { getCurrentUser, getCurrentSubscription } from "store/reducers/currentUser";
import { User, Role } from "store/models/users";
import { getContent, strings } from "content";
import ErrorHandler from "components/ErrorHandler";
import { generateRequestId } from "utils/api";
import * as H from "history";
import { decodedEmail, mailToString, generateEmailSubject } from "utils/links";
import SelectOptionDialog from "components/GenericDialogs/SelectOptionDialog";
import { getDisplayName } from "utils/text";

const NAVBAR_ID = "navbar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    list: {
      width: 250,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    leftItems: {
      flexGrow: 1,
    },
  }),
);

interface ComponentProps {
  navItems: NavItem[];
  currentPage: NavItemProps;
}
interface StoreProps {
  dataModelTitles: Record<string, string>;
  currentUser: User | void;
  activeSubscription: Role | undefined;
}
interface DispatchProps {
  userLogout: typeof userLogout;
  deleteAuthTokens: typeof deleteAuthTokens;
  setActiveSubscription: (role: Role) => void;
}
type Props = ComponentProps & DispatchProps & StoreProps & RouteComponentProps;

export const logoutUser = (props: {
  history: H.History;
  deleteAuthTokens: typeof deleteAuthTokens;
  userLogout: typeof userLogout;
}): void => {
  props.deleteAuthTokens(NAVBAR_ID, () => {
    localStorage.removeItem("token");
    props.userLogout();
    props.history.push("/login");
  });
};

export const NavBar: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { currentUser, activeSubscription } = props;

  // Side Drawer
  const [showSetActiveSubscriptionDialog, setShowSetActiveSubscriptionDialog] = React.useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [accountsDropdownAnchorEl, setAccountsDropdownAnchorEl] = React.useState<null | HTMLElement>(null);
  const accountDropDownOpen = Boolean(accountsDropdownAnchorEl);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent): void => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const modelsDrawerItem = { displayName: getContent("modelsButtonLabel"), path: "/models" };
  const teamsDrawerItem = { displayName: getContent("teamsButtonLabel"), path: "/teams" };
  const usersDrawerItem = { displayName: getContent("usersButtonLabel"), path: "/users" };
  const subscriptionsDrawerItem = { displayName: getContent("subscriptionsButtonLabel"), path: "/subscriptions" };

  let drawerItems = [modelsDrawerItem];
  if (activeSubscription && activeSubscription.role === "system-admin") {
    drawerItems = drawerItems.concat([usersDrawerItem, teamsDrawerItem, subscriptionsDrawerItem]);
  } else if (props.activeSubscription && props.activeSubscription.role === "subscription-admin") {
    drawerItems = drawerItems.concat([usersDrawerItem, teamsDrawerItem]);
  }

  const drawerElements = drawerItems.map((item) => {
    return (
      <div className={classes.list} role="presentation" key={item.displayName}>
        <ListItem
          button
          onClick={(): void => {
            setDrawerOpen(false);
            props.history.push(item.path);
          }}
        >
          <ListItemText data-testid="drawer-item-text" primary={item.displayName} />
        </ListItem>
      </div>
    );
  });

  // Hamburger menu

  const openAccountsDropdown = (event: React.MouseEvent<HTMLElement>): void => {
    setAccountsDropdownAnchorEl(event.currentTarget);
  };

  const closeAccountsDropdown = (): void => {
    setAccountsDropdownAnchorEl(null);
  };
  const dataModelTitle =
    props.dataModelTitles[
      (props.match.params as Record<string, string>)[props.currentPage.pathMeta.dataModelIdKey || ""]
    ];

  const profileItems = (user: User | void): ReactElement[] => {
    const defaultItems = [
      <MenuItem
        key="contactUs"
        data-test-id="contact-us-button"
        onClick={(): void => {
          closeAccountsDropdown();
          window.location.href = mailToString({
            email: decodedEmail,
            subject: generateEmailSubject((user || {}).id),
          });
        }}
      >
        {strings.contactUsAccountsDropDownLabel}
      </MenuItem>,
      <MenuItem
        key="userGuide"
        data-test-id="user-guide-button"
        onClick={(): void => {
          closeAccountsDropdown();
          props.history.push("/user_guide");
        }}
      >
        {strings.userGuideAccountsDropDownLabel}
      </MenuItem>,
      <MenuItem
        key="technicalGuide"
        data-test-id="technical-guide-button"
        onClick={(): void => {
          closeAccountsDropdown();
          props.history.push("/technical_guide");
        }}
      >
        {strings.technicalGuideAccountsDropDownLabel}
      </MenuItem>,
    ];
    let profileItems = defaultItems;
    if (user) {
      if (user.roles.length > 1 || (activeSubscription && activeSubscription?.role === "system-admin")) {
        profileItems = [
          <MenuItem
            key="setActiveSubscription"
            data-test-id="set-active-subscription"
            onClick={(): void => {
              closeAccountsDropdown();
              setShowSetActiveSubscriptionDialog(true);
            }}
          >
            {strings.setActiveSubscriptionButton}
          </MenuItem>,
        ].concat(profileItems);
      }
      profileItems = [
        <MenuItem
          key="logout"
          data-test-id="logout-button"
          onClick={(): void => {
            closeAccountsDropdown();
            logoutUser({
              deleteAuthTokens: props.deleteAuthTokens,
              userLogout: props.userLogout,
              history: props.history,
            });
          }}
        >
          {getContent("logutButtonLabel")}
        </MenuItem>,
      ].concat(profileItems);
    }
    return profileItems;
  };

  const activeSubscriptionName = activeSubscription?.subscriptionName
    ? getDisplayName(activeSubscription.subscriptionName)
    : undefined;

  return (
    <ErrorHandler requestsToHandle={[generateRequestId(DELETE_AUTH_TOKENS_REQUEST_ID, NAVBAR_ID)]}>
      <div className={classes.root}>
        {/* Side Drawer */}
        <Drawer open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawerElements}
        </Drawer>
        {/* Nav Bar */}
        <AppBar position="static">
          <Toolbar>
            {/* Left content */}
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              data-testid="hamburger"
              onClick={toggleDrawer(!drawerOpen)}
            >
              <MenuIcon />
            </IconButton>
            <div className={classes.leftItems}>
              <Typography data-testid="page-title" variant="h6" className={classes.title}>
                {activeSubscriptionName || dataModelTitle || props.currentPage.pathMeta.defaultDisplayName}
              </Typography>

              <NavBreadcrumbs currentPage={props.currentPage} />
            </div>
            {/* Right content */}
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                data-test-id="profile-button"
                onClick={openAccountsDropdown}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={accountsDropdownAnchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={accountDropDownOpen}
                onClose={closeAccountsDropdown}
              >
                {profileItems(props.currentUser)}
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <SelectOptionDialog
          open={showSetActiveSubscriptionDialog}
          onClose={(): void => {
            setShowSetActiveSubscriptionDialog(false);
          }}
          title={strings.setActiveSubscriptionDialogTitle}
          description={strings.setActiveSubscriptionDialogDescription}
          optionSelected={(option: Role): void => {
            props.setActiveSubscription(option);
            setShowSetActiveSubscriptionDialog(false);
            window.location.reload();
          }}
          options={currentUser ? currentUser.roles : []}
          fetchOptions={(): void => {}}
          optionToDisplayName={(option: Role): string => {
            return option.subscriptionName ? getDisplayName(option.subscriptionName) : "";
          }}
          optionToId={(option: Role): string => {
            return option.id;
          }}
          optionFromId={(id: string, options: Role[]): Role => {
            return options.filter((option) => id === option.id)[0];
          }}
          initialOption={activeSubscription || undefined}
        />
      </div>
    </ErrorHandler>
  );
};

const mapStateToProps = (state: StoreState): StoreProps => {
  return {
    dataModelTitles: state.navigation.dataModelTitles,
    currentUser: getCurrentUser(state),
    activeSubscription: getCurrentSubscription(state) || undefined,
  };
};

const dispatchProps: DispatchProps = {
  userLogout,
  deleteAuthTokens,
  setActiveSubscription: setActiveSubscription,
};

export const NavbarWithRouter = withRouter(NavBar);

export default connect(mapStateToProps, dispatchProps)(NavbarWithRouter);
