import * as t from "io-ts";
import {
  ApiRequestAction,
  ApiRequstCallbackAction,
  ApiRequestMeta,
  UpdateSubscriptionsListAction,
  UpdateSubscriptionAction,
} from "./../../types/store";
import * as constants from "../../constants";
import { Subscription, SubscriptionValidator } from "store/models/subscriptions";
import { generateRequestId } from "utils/api";
import { apiRequest } from "./api";

export function updateSubscriptionsList(subscriptions: Subscription[], listId: string): UpdateSubscriptionsListAction {
  return {
    type: constants.UPDATE_SUBSCRIPTION_LIST,
    payload: { listId, items: subscriptions },
  };
}

export function updateSubscription(subscription: Subscription): UpdateSubscriptionAction {
  return {
    type: constants.UPDATE_SUBSCRIPTION,
    payload: subscription,
  };
}

export const FETCH_SUBSCRIPTIONS_REQUEST_ID = "fetcSubscriptionsRequest";
export const fetchSubscriptions = (listId: string, onComplete = (): void => {}): ApiRequestAction<Subscription[]> => {
  const onSuccess: ApiRequstCallbackAction<Subscription[]> = (results) => (dispatch): void => {
    dispatch(updateSubscriptionsList(results, listId));
    onComplete();
  };

  const requestMeta: ApiRequestMeta<Subscription[]> = {
    validator: t.array(SubscriptionValidator),
    onSuccessAction: onSuccess,
    onErrorAction: () => (): void => {},
    url: `/subscriptions`,
    method: "GET",
    headers: {},
    id: generateRequestId(FETCH_SUBSCRIPTIONS_REQUEST_ID, listId),
  };
  return apiRequest(requestMeta);
};

export const DELETE_SUBSCRIPTION_REQUEST_ID = "deleteSubscriptionRequest";
export const deleteSubscriptionRequest = (
  subscriptionId: string,
  onComplete?: () => void,
): ApiRequestAction<unknown> => {
  const onSuccess: ApiRequstCallbackAction<unknown> = () => (): void => {
    if (onComplete) {
      onComplete();
    }
  };
  const requestMeta: ApiRequestMeta<unknown> = {
    onSuccessAction: onSuccess,
    onErrorAction: () => (): void => {},
    url: `/subscription/${subscriptionId}`,
    method: "DELETE",
    headers: {},
    id: generateRequestId(DELETE_SUBSCRIPTION_REQUEST_ID, subscriptionId),
  };
  return apiRequest(requestMeta);
};

export interface CreateSubscriptionPayload {
  name: string;
  maxNumberOfSeats: number | null;
}

export const CREATE_SUBSCRIPTION_REQUEST_ID = "createSubscriptionsRequest";
export const createSubscription = (
  createSubscriptionPayload: CreateSubscriptionPayload,
  createdId?: (id: string) => void,
): ApiRequestAction<Subscription> => {
  const onSuccess: ApiRequstCallbackAction<Subscription> = (result) => (dispatch): void => {
    dispatch(updateSubscription(result));
    if (createdId) {
      createdId(result.id);
    }
  };
  const requestMeta: ApiRequestMeta<Subscription> = {
    validator: SubscriptionValidator,
    onSuccessAction: onSuccess,
    onErrorAction: () => (): void => {},
    url: `/subscriptions`,
    method: "POST",
    body: createSubscriptionPayload,
    headers: {},
    id: generateRequestId(CREATE_SUBSCRIPTION_REQUEST_ID, ""),
  };
  return apiRequest(requestMeta);
};

export const FETCH_SUBSCRIPTION_REQUEST_ID = "getSubscriptionsRequest";
export const fetchSubscription = (subscriptionId: string): ApiRequestAction<Subscription> => {
  const onSuccess: ApiRequstCallbackAction<Subscription> = (result) => (dispatch): void => {
    dispatch(updateSubscription(result));
  };
  const requestMeta: ApiRequestMeta<Subscription> = {
    validator: SubscriptionValidator,
    onSuccessAction: onSuccess,
    onErrorAction: () => (): void => {},
    url: `/subscription/${subscriptionId}`,
    method: "GET",
    headers: {},
    id: generateRequestId(FETCH_SUBSCRIPTION_REQUEST_ID, subscriptionId),
  };
  return apiRequest(requestMeta);
};

export interface PatchSubscriptionPayload extends Partial<Pick<Subscription, "name" | "maxNumberOfSeats">> {
  active?: boolean;
}

export const PATCH_SUBSCRIPTION_REQUEST_ID = "patchSubscriptionsRequest";
export const patchSubscription = (
  subscriptionId: string,
  patchSubscriptionPayload: PatchSubscriptionPayload,
  onComplete?: () => void,
): ApiRequestAction<Subscription> => {
  const onSuccess: ApiRequstCallbackAction<Subscription> = (result) => (dispatch): void => {
    dispatch(updateSubscription(result));
    if (onComplete) {
      onComplete();
    }
  };
  const requestMeta: ApiRequestMeta<Subscription> = {
    validator: SubscriptionValidator,
    onSuccessAction: onSuccess,
    onErrorAction: () => (): void => {},
    url: `/subscription/${subscriptionId}`,
    method: "PATCH",
    body: patchSubscriptionPayload,
    headers: {},
    id: generateRequestId(PATCH_SUBSCRIPTION_REQUEST_ID, subscriptionId),
  };
  return apiRequest(requestMeta);
};
