import { fetchSubscriptions } from "store/actions/subscriptions";
import {
  UserLogoutAction,
  SetUserAction,
  ApiRequestAction,
  ApiRequstCallbackAction,
  ApiRequestMeta,
  SetActiveSubscriptionAction,
} from "./../../types/store";
import * as constants from "../../constants";
import { User, UserValidator, Role } from "store/models/users";
import { updateUser } from "./users";
import { generateRequestId } from "utils/api";
import { apiRequest } from "./api";
import { Dispatch } from "react";
import { Subscription } from "store/models/subscriptions";

export function userLogout(): UserLogoutAction {
  return {
    type: constants.USER_LOGOUT,
  };
}

export function setCurrentUser(user: User): SetUserAction {
  return {
    type: constants.SET_CURRENT_USER,
    payload: user,
  };
}

export function setActiveSubscription(role: Role): SetActiveSubscriptionAction {
  return {
    type: constants.SET_ACTIVE_SUBSCRIPTION_USER,
    payload: role,
  };
}

export const FETCH_CURRENT_USER_REQUEST_ID = "getCurrentUsersRequest";
export const fetchCurrentUser = (onComplete = (): void => {}): ApiRequestAction<User> => {
  const onSuccess: ApiRequstCallbackAction<User> = (result) => (dispatch): void => {
    dispatch(setCurrentUser(result));
    dispatch(updateUser(result));
    onComplete();
  };

  const requestMeta: ApiRequestMeta<User> = {
    validator: UserValidator,
    onSuccessAction: onSuccess,
    onErrorAction: () => (): void => {},
    url: `/me`,
    method: "GET",
    headers: {},
    id: generateRequestId(FETCH_CURRENT_USER_REQUEST_ID, ""),
  };
  return apiRequest(requestMeta);
};

export const REFRESH_CURRENT_USER_REQUEST_ID = "refreshCurrentUsersRequest";
export const refreshCurrentUser = (
  onComplete = (): void => {},
): ((dispatch: Dispatch<ApiRequestAction<User> | ApiRequestAction<Subscription[]>>) => void) => {
  return (dispatch): void => {
    dispatch(
      fetchCurrentUser(() => {
        // Fetching the subscriptions will enrich the users roles with their relevant subscription names
        dispatch(
          fetchSubscriptions(REFRESH_CURRENT_USER_REQUEST_ID, () => {
            onComplete();
          }),
        );
      }),
    );
  };
};

export interface PatchUserPayload {
  termsAccepted: boolean;
}

export const PATCH_CURRENT_USER_REQUEST_ID = "patchCurrentUsersRequest";
export const patchCurrentUser = (payload: PatchUserPayload): ApiRequestAction<User> => {
  const onSuccess: ApiRequstCallbackAction<User> = (result) => (dispatch): void => {
    dispatch(setCurrentUser(result));
    dispatch(updateUser(result));
  };

  const requestMeta: ApiRequestMeta<User> = {
    validator: UserValidator,
    onSuccessAction: onSuccess,
    onErrorAction: () => (): void => {},
    url: `/me`,
    method: "PATCH",
    body: {
      "terms_accepted": payload.termsAccepted,
    },
    headers: {},
    id: generateRequestId(FETCH_CURRENT_USER_REQUEST_ID, ""),
  };
  return apiRequest(requestMeta);
};

export const DELETE_AUTH_TOKENS_REQUEST_ID = "deleteAuthTokensRequest";
export const deleteAuthTokens = (sourceId: string, successCallBack: () => void): ApiRequestAction<unknown> => {
  const onSuccess: ApiRequstCallbackAction<unknown> = () => (): void => {
    successCallBack();
  };

  const requestMeta: ApiRequestMeta<unknown> = {
    onSuccessAction: onSuccess,
    onErrorAction: () => (): void => {},
    url: `/auth_tokens`,
    method: "DELETE",
    headers: {},
    id: generateRequestId(DELETE_AUTH_TOKENS_REQUEST_ID, sourceId),
  };
  return apiRequest(requestMeta);
};
