import * as constants from "../../constants";
import { ApiRequestMeta, ApiRequestAction } from "types/store";
import {} from "../../types/store";

export function apiRequest<Model>(requestMeta: ApiRequestMeta<Model>): ApiRequestAction<Model> {
  return {
    type: constants.API_REQUEST,
    meta: requestMeta,
  };
}
