import queryString from "query-string";
import { parse, strings } from "content";

// Base 64 decoded "analytics.support@alantra.com"
export const decodedEmail = atob("YW5hbHl0aWNzLnN1cHBvcnRAYWxhbnRyYS5jb20=");

export const generateEmailSubject = (userId: string | undefined): string => {
  if (userId) {
    return parse(strings.contactUsEmailSubjectWithUserId, userId);
  }
  return strings.contactUsEmailSubject;
};

interface MailToStringParams {
  email: string;
  subject?: string;
}

export const mailToString = (params: MailToStringParams): string => {
  let mailTo = `mailto:${params.email}`;
  const query = queryString.stringify({ subject: params.subject });
  if (query) {
    mailTo += `?${query}`;
  }
  return mailTo;
};
