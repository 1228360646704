import "react-app-polyfill/stable";
import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "store";
import * as Sentry from "@sentry/browser";
import { env } from "utils/config";

Sentry.init({ dsn: env.sentryDsn, environment: env.sentryEnv });

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root"),
);
