import React, { ReactElement, ReactNode } from "react";
import { WithStyles, createStyles, withStyles, Theme } from "@material-ui/core";
import { StoreState, ApiRequestStateError } from "types/store";
import { connect } from "react-redux";
import { ErrorModel, ApplicationError, ApplicationErrors } from "store/models/errors";
import { getContent } from "content";
import { withSnackbar, WithSnackbarProps } from "notistack";
import hash from "object-hash";
import Retry from "./Retry";
import { strings } from "content";

const DELAY_DURATION = 4000;

interface StoreProps {
  // Errors that are derived from the redux store
  storeErrors?: ErrorModel[];
}

interface DispatchProps {}

interface ComponentState {
  // Errors raised during the react life cycle. For more info see React Error boundaries
  reactLifeCycleError?: ApplicationErrors;
  errorHashesAlreadyHandled: Set<string>;
}

interface ParentProps {
  children: ReactNode;
  // The specific error keys to handle
  errorsToHandle?: ErrorModel["key"][];
  // These are errors that the parent is explicitly telling the ErrorHandler to handle
  parentErrors?: ErrorModel[];
  // These are requests that the Error handler should listen to incase there is an apiError
  requestsToHandle?: string[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const styles = (theme: Theme): any =>
  createStyles({
    snackBar: {
      backgroundColor: theme.palette.error.main,
    },
  });

type Props = StoreProps & DispatchProps & ParentProps & WithStyles<typeof styles> & WithSnackbarProps;

/** A Generic error handler component to handle api and render errors
 *
 * 1) Errors during render are caught using React Error boundaries and can show a
 * snackbar if required
 * 2) ApiErrors are handled by hooking into the store and listening to errors generated
 * by specific requests
 */
export class ErrorHandler extends React.Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = { errorHashesAlreadyHandled: new Set([]) };
  }

  static getDerivedStateFromError(error: Error | null): Partial<ComponentState> {
    if (error instanceof ApplicationError) {
      return { reactLifeCycleError: error.error };
    }
    return {};
  }

  componentDidCatch(error: Error | null): void {
    if (error instanceof ApplicationError) {
      this.setState({ reactLifeCycleError: error.error });
    }
  }

  /** Combines all errors gathered from the store, the parent */
  allErrors(): ErrorModel[] {
    return (this.props.storeErrors || []).concat(this.props.parentErrors || []);
  }

  errorReasons(error: ErrorModel): string[] {
    if (error.metadata && error.metadata.reasons instanceof Array) {
      return error.metadata.reasons.map((reason) => String(reason));
    } else if (error.metadata && error.metadata.message) {
      return [error.metadata.message];
    } else {
      return [];
    }
  }

  componentDidUpdate(): void {
    const errorsToHandle = this.allErrors()
      // If the parent has specifified what errors they are interested in then use those, otherwhise handle all errors
      .filter((error) => this.props.errorsToHandle === undefined || this.props.errorsToHandle.includes(error.key))
      // Only handle errors that have not already been handled
      .filter((error) => !this.state.errorHashesAlreadyHandled.has(hash(error)));

    // Add hashed new errors to the alreadyHandledErrors
    if (errorsToHandle.length > 0) {
      const newErrorHashesAlreadyHandled = new Set([
        ...this.state.errorHashesAlreadyHandled,
        ...errorsToHandle.map((err) => hash(err)),
      ]);
      this.setState(
        {
          errorHashesAlreadyHandled: newErrorHashesAlreadyHandled,
        },
        () => {
          errorsToHandle.forEach((error) => {
            const message = this.errorReasons(error).join(", ") || getContent(error.key);
            this.props.enqueueSnackbar(message, {
              key: hash(error),
              preventDuplicate: true,
              variant: "error",
              autoHideDuration: DELAY_DURATION,
            });
          });
        },
      );
    }
  }

  lifeCycleErrorMessage(): string {
    const error = this.state.reactLifeCycleError;
    if (error) {
      return error.metadata.reasons.join("\n");
    }
    return "";
  }

  render(): ReactElement | ReactNode {
    const { reactLifeCycleError } = this.state;
    return (
      <>
        {reactLifeCycleError !== undefined && (
          <Retry
            errorMessage={strings.fatalLifeCycleErrorMessage}
            details={reactLifeCycleError.metadata.reasons}
            retryAction={(): void => window.location.reload()}
          />
        )}
        {!this.state.reactLifeCycleError && this.props.children}
      </>
    );
  }
}

function mapStateToProps(state: StoreState, props: ParentProps): StoreProps {
  const { requestsToHandle } = props;
  return {
    storeErrors: Object.entries(state.apiRequests || {})
      // First if the parent has passed in requests to handle then check then filter out all other requests,
      // if requestsToHandle are not passed in then we assume they want to handel all requests
      .filter((entry) => !requestsToHandle || requestsToHandle.includes(entry[0]))
      .map((entry) => entry[1])
      // Then we only pass requests that have failed in the the specified window. We are not interested in Old requests
      .filter(
        (request) => request.state === "error" && Date.now() - request.meta.error.createdAt.getTime() < DELAY_DURATION,
      )
      // Extract the actual error as that is what we are interested in here.
      .map((request) => (request as ApiRequestStateError).meta.error),
  };
}

const mapDispatchToProps: DispatchProps = {};

export const StyledErrorHandler = withStyles(styles)(withSnackbar(ErrorHandler));

export const ConnectedErrorHandler = connect(mapStateToProps, mapDispatchToProps)(StyledErrorHandler);

export default ConnectedErrorHandler;
