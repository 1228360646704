import React, { ReactElement } from "react";
import {
  WithStyles,
  createStyles,
  withStyles,
  TextField,
  Box,
  Button,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Page, { SettingsData } from "pages/Page";
import { StoreState, ApiRequestStates } from "types/store";
import { connect } from "react-redux";
import { strings } from "content";
import PlainInputSection from "components/Form/PlainInputSection";
import {
  fetchSubscription,
  patchSubscription,
  deleteSubscriptionRequest,
  createSubscription,
  FETCH_SUBSCRIPTION_REQUEST_ID,
  CREATE_SUBSCRIPTION_REQUEST_ID,
  PATCH_SUBSCRIPTION_REQUEST_ID,
  CreateSubscriptionPayload,
  PatchSubscriptionPayload,
} from "store/actions/subscriptions";
import { pruneObject } from "utils/objects";
import { FieldValidity, validateTextField, validateIntTextField } from "utils/forms";
import { getSubscription } from "store/reducers/subscriptions";
import { apiRequestState, isRequestLoading } from "store/reducers/apiRequests";
import Loader from "components/Loader";
import ErrorHandler from "components/ErrorHandler";
import { generateRequestId } from "utils/api";
import ConfirmationDialog from "components/ConfirmationDialog";
import { getCurrentSubscription, getCurrentUser } from "store/reducers/currentUser";
import { Subscription } from "store/models/subscriptions";
import { Role, User } from "store/models/users";
import {
  CreateRolePayload,
  createRole,
  CREATE_ROLE_REQUEST_ID,
  DELETE_ROLE_REQUEST_ID,
  deleteRoleRequest,
} from "store/actions/roles";
import { refreshCurrentUser } from "store/actions/currentUser";

interface Validityindex {
  name: FieldValidity;
  maxNumberOfSeats: FieldValidity;
  overallValidity: boolean;
}

interface StoreProps {
  subscription?: Subscription;
  currentUser: User | void;
  activeSubscription: Role | void;
  fetchingSubscriptionRequestState?: ApiRequestStates;
  isCreatingRole: boolean;
  isDeletingRole: (roleId: string) => boolean;
}

interface DispatchProps {
  patchSubscription: (
    subscriptionId: string,
    patchUserPayload: PatchSubscriptionPayload,
    onComplete?: () => void,
  ) => void;
  createRole: (subscriptionId: string, role: CreateRolePayload, onComplete: () => void) => void;
  deleteRole: (subscriptionId: string, roleId: string, onComplete: () => void) => {};
  createSubscription: (createUserPayload: CreateSubscriptionPayload, createdId?: (id: string) => void) => void;
  deleteSubscription: (subscriptionId: string, onComplete: () => void) => void;
  fetchSubscription: (subscriptionId: string) => void;
  refreshCurrentUser: (onComplete: () => void) => void;
}

interface ComponentState {
  nameFromField?: string;
  maxNumberOfSeatsFromField?: string;
  showDeactivateConfirmationDialog: boolean;
}

interface ParentProps {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const styles = (): any =>
  createStyles({
    addDataSection: {
      width: "100%",
    },
    addDataSectionWrapper: {
      maxWidth: "600px",
      width: "100%",
    },
    textField: {
      width: "100%",
    },
  });
export type BaseProps = StoreProps & DispatchProps & ParentProps;
type Props = BaseProps & RouteComponentProps<{ subscriptionId?: string }> & WithStyles<typeof styles>;

export class AddEditViewSubscription extends React.Component<Props, ComponentState> {
  constructor(props: Props) {
    super(props);
    this.state = { showDeactivateConfirmationDialog: false };
  }

  validityIndex(): Validityindex {
    const fieldValidities = {
      name: validateTextField(strings.addEditViewSubscriptionPageNameLabel, this.state.nameFromField, this.name()),
      maxNumberOfSeats: validateIntTextField(
        strings.addEditViewSubscriptionPageNameLabel,
        this.state.maxNumberOfSeatsFromField,
        this.maxNumberOfSeats(),
        {
          min: this.props.subscription?.seatsInUse || 0,
          belowMinErrorMessage: this.props.subscription?.seatsInUse
            ? strings.numberOfSeatsCantBeLessThanCurrentSeats
            : undefined,
          nullable: true,
        },
      ),
    };
    return {
      ...fieldValidities,
      overallValidity: Object.values(fieldValidities).every((value) => value.isValid),
    };
  }

  name(): string | undefined {
    if (this.state.nameFromField === undefined) {
      return (this.props.subscription || {}).name;
    }
    return this.state.nameFromField;
  }

  maxNumberOfSeats(): string | undefined {
    if (this.state.maxNumberOfSeatsFromField === undefined) {
      const seats = (this.props.subscription || {}).maxNumberOfSeats;
      return seats === undefined || seats === null ? "" : String(seats);
    }
    return this.state.maxNumberOfSeatsFromField.trim();
  }

  componentDidMount(): void {
    const subscriptionId = this.subscriptionId();
    if (subscriptionId) {
      this.props.fetchSubscription(subscriptionId);
    }
  }

  subscriptionId(): string | void {
    return this.props.match.params.subscriptionId;
  }

  saveButtonEnabled(): boolean {
    const { subscription } = this.props;
    const { nameFromField } = this.state;
    const validityIndex = this.validityIndex();
    // If we have a base subscription we are concerned with if the
    // data is is valid and has changed from original base
    if (subscription) {
      return validityIndex.overallValidity && this.dataHasChanged();
    }
    // If there is no base user then we only care about if some data has been supplied
    // is valid.
    return nameFromField !== undefined && validityIndex.overallValidity;
  }

  deleteButtonEnabled(): boolean {
    const { subscription } = this.props;
    return subscription !== undefined;
  }

  showDeleteButton(): boolean {
    return this.subscriptionId() !== undefined;
  }

  saveSubscription(): void {
    const subscriptionId = this.props.subscription?.id;
    const name = this.name();
    const maxNumberOfSeats = this.maxNumberOfSeats();
    const _maxNumberOfSeats = maxNumberOfSeats ? Number(maxNumberOfSeats) : null;
    if (subscriptionId && name) {
      // If there is a userId it means we are modifying an existing user
      this.props.patchSubscription(subscriptionId, pruneObject({ name, maxNumberOfSeats: _maxNumberOfSeats }), () => {
        this.props.history.push("/subscriptions");
      });
    } else {
      // is creating new user
      if (name) {
        this.props.createSubscription({ name, maxNumberOfSeats: _maxNumberOfSeats }, () => {
          this.props.history.push("/subscriptions");
        });
      }
    }
  }

  deactivateSubscription(): void {
    const subscriptionId = this.subscriptionId();
    if (subscriptionId) {
      this.props.deleteSubscription(subscriptionId, () => {
        this.props.history.push("/subscriptions");
      });
    }
  }

  dataHasChanged(): boolean {
    // Figure out if the data added by the user has diverged from the original data supplied by the backend
    // If there is no reference data then it will return undefined
    const { subscription } = this.props;
    const { nameFromField, maxNumberOfSeatsFromField } = this.state;
    if (subscription) {
      const nameHasChanged = nameFromField ? subscription.name !== nameFromField : false;
      const maxNumberOfSeatsHasChanges =
        maxNumberOfSeatsFromField === undefined
          ? false
          : maxNumberOfSeatsFromField === "" || subscription.maxNumberOfSeats !== Number(maxNumberOfSeatsFromField);
      // If we have a user then atleast one field should differ from the base user
      return nameHasChanged || maxNumberOfSeatsHasChanges;
    }
    if (!subscription && nameFromField && maxNumberOfSeatsFromField) {
      // If we have no user, atleast one field should be changed
      return Boolean(nameFromField || maxNumberOfSeatsFromField);
    }
    return false;
  }

  shouldShowLoader(): boolean {
    const { fetchingSubscriptionRequestState } = this.props;
    if (fetchingSubscriptionRequestState) {
      return fetchingSubscriptionRequestState.state === "loading";
    }
    return false;
  }

  errorRequestsToHandle(): string[] {
    const subscriptionId = this.subscriptionId() || "";
    return [
      generateRequestId(FETCH_SUBSCRIPTION_REQUEST_ID, subscriptionId),
      generateRequestId(CREATE_SUBSCRIPTION_REQUEST_ID, ""),
      generateRequestId(PATCH_SUBSCRIPTION_REQUEST_ID, subscriptionId),
    ];
  }

  isAdmin(): boolean {
    return (
      this.props.activeSubscription !== undefined &&
      (this.props.activeSubscription.role === "system-admin" ||
        this.props.activeSubscription.role === "subscription-admin")
    );
  }

  isSystemAdmin(): boolean {
    return this.props.activeSubscription !== undefined && this.props.activeSubscription.role === "system-admin";
  }

  pageSettings(): SettingsData[] {
    const { subscription, currentUser } = this.props;
    const settings = [];
    if (subscription && currentUser && this.isSystemAdmin()) {
      const currentUsersRoleForSubscription = currentUser.roles.filter(
        (role) => role.subscriptionId == subscription.id,
      )[0];
      if (currentUsersRoleForSubscription) {
        settings.push({
          id: "remove_from_subscription",
          label: strings.removeFromSubscriptionLabel,
          onClick: (): void => {
            this.props.deleteRole(subscription.id, currentUsersRoleForSubscription.id, () => {
              this.props.refreshCurrentUser(() => {});
            });
          },
        });
      } else {
        settings.push({
          id: "add_to_subscription",
          label: strings.addToSubscriptionLabel,
          onClick: (): void => {
            this.props.createRole(subscription.id, { userId: currentUser.id, role: "subscription-admin" }, () => {
              this.props.refreshCurrentUser(() => {});
            });
          },
        });
      }
    }
    return settings;
  }

  updatingRolesText(): string {
    const { subscription, currentUser } = this.props;
    if (this.props.isCreatingRole) {
      return strings.creatingRolesLabel;
    }
    if (subscription && currentUser) {
      const role = currentUser.roles.filter((role) => role.subscriptionId == subscription.id)[0];
      if (role && this.props.isDeletingRole(role.id)) {
        return strings.deletingRolesLabel;
      }
    }
    return "";
  }

  isBusyUpdatingRoles(): boolean {
    const { subscription, currentUser } = this.props;
    let isUpdating = this.props.isCreatingRole;
    if (subscription && currentUser && !isUpdating) {
      const role = currentUser.roles.filter((role) => role.subscriptionId == subscription.id)[0];
      if (role && this.props.isDeletingRole(role.id)) {
        isUpdating = true;
      }
    }
    return isUpdating;
  }

  render(): ReactElement {
    const { subscription } = this.props;
    const { showDeactivateConfirmationDialog } = this.state;
    const validityIndex = this.validityIndex();
    return (
      <ErrorHandler requestsToHandle={this.errorRequestsToHandle()}>
        <Page
          title={this.props.subscription ? this.props.subscription.name : strings.addEditViewSubscriptionPageTitle}
          settings={this.pageSettings()}
        >
          {this.shouldShowLoader() && <Loader />}
          {!this.shouldShowLoader() && (
            <>
              <Box
                className={this.props.classes.addDataSectionWrapper}
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
              >
                <PlainInputSection
                  title={strings.subscriptionDetailsGeneralSectionTitle}
                  className={this.props.classes.addDataSection}
                >
                  <Box mb={2}>
                    <TextField
                      value={this.name() || ""}
                      error={!validityIndex.name.isValid}
                      helperText={validityIndex.name.invalidReason}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                        this.setState({ nameFromField: event.target.value });
                      }}
                      className={this.props.classes.textField}
                      data-testid="name-textfield"
                      inputProps={{ "data-testid": "name-textfield-input" }}
                      label={strings.addEditViewSubscriptionPageNameLabel}
                    />
                  </Box>
                  <Box mb={2} display="flex">
                    <Box mr={2} minWidth={"100px"}>
                      <TextField
                        value={this.maxNumberOfSeats() || ""}
                        error={!validityIndex.maxNumberOfSeats.isValid}
                        helperText={validityIndex.maxNumberOfSeats.invalidReason}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                          this.setState({ maxNumberOfSeatsFromField: event.target.value });
                        }}
                        className={this.props.classes.textField}
                        data-testid="max-number-of-seats-textfield"
                        inputProps={{ "data-testid": "max-number-of-seats-textfield-input" }}
                        label={strings.addEditViewSubscriptionPageMaxNumberOfSeatsLabel}
                        placeholder={strings.addEditViewSubscriptionPageInfinitePlacholder}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Box>
                    {subscription && (
                      <Box minWidth={"100px"}>
                        <TextField
                          value={subscription.seatsInUse}
                          disabled={true}
                          className={this.props.classes.textField}
                          data-testid="seats-used-textfield"
                          label={strings.addEditViewSubscriptionSeatsUsedLabel}
                          placeholder={strings.addEditViewSubscriptionPageInfinitePlacholder}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </PlainInputSection>
              </Box>
              <Box my={2}>
                <Button
                  disabled={!this.saveButtonEnabled()}
                  onClick={(): void => this.saveSubscription()}
                  variant="contained"
                  color="primary"
                  data-testid="save-button"
                >
                  {strings.saveSubscriptionButtonTitle}
                </Button>
                {/* {this.showDeleteButton() && (
                  <>
                    <Box component="span" px={1} />
                    <Button
                      disabled={!this.deleteButtonEnabled()}
                      onClick={(): void => this.setState({ showDeactivateConfirmationDialog: true })}
                      color="primary"
                      data-testid="delete-button"
                    >
                      {strings.deleteSubscriptionButtonTitle}
                    </Button>
                  </>
                )} */}
              </Box>
            </>
          )}
        </Page>
        <ConfirmationDialog
          open={showDeactivateConfirmationDialog}
          onClose={(): void => this.setState({ showDeactivateConfirmationDialog: false })}
          title={strings.confirmDeactivateSubscriptionTitle}
          description={strings.confirmDeactivateSubscriptionDescription}
          onConfirm={(): void => {
            this.setState({ showDeactivateConfirmationDialog: false });
            this.deactivateSubscription();
          }}
        />
        <Snackbar
          data-testid="downloading-model-loading-indicator"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.isBusyUpdatingRoles()}
          // autoHideDuration={6000}
          onClose={(): void => {}}
          message={
            <Box display="flex">
              <span id="message-id">{this.updatingRolesText()}</span>
              <Box mx={1}>
                <CircularProgress size={20} color="inherit" />
              </Box>
            </Box>
          }
        />
      </ErrorHandler>
    );
  }
}

function mapStateToProps(state: StoreState, props: Props): StoreProps {
  const subscriptionId = props.match.params.subscriptionId;
  return {
    activeSubscription: getCurrentSubscription(state),
    currentUser: getCurrentUser(state),
    subscription: getSubscription(state.subscriptions, String(subscriptionId || "")) || undefined,
    isCreatingRole: isRequestLoading(state.apiRequests, generateRequestId(CREATE_ROLE_REQUEST_ID, "")),
    isDeletingRole: (roleId): boolean =>
      isRequestLoading(state.apiRequests, generateRequestId(DELETE_ROLE_REQUEST_ID, roleId)),
    fetchingSubscriptionRequestState: apiRequestState(
      state.apiRequests,
      FETCH_SUBSCRIPTION_REQUEST_ID,
      subscriptionId || "",
    ),
  };
}

const mapDispatchToProps: DispatchProps = {
  createSubscription,
  deleteSubscription: (userId: string, onComplete: () => void) => deleteSubscriptionRequest(userId, onComplete),
  patchSubscription,
  fetchSubscription,
  createRole,
  deleteRole: deleteRoleRequest,
  refreshCurrentUser,
};

export const BaseAddEditViewSubscription = withStyles(styles)(withRouter(AddEditViewSubscription));

export default connect(mapStateToProps, mapDispatchToProps)(BaseAddEditViewSubscription);
