import * as constants from "../../constants/index";
import { createReducer } from "typesafe-actions";
import { SimulationModelDraftState, StoreState } from "types/store";
import { SimulationModelDraft } from "store/models/simulationModelDraft";

export const initialState: SimulationModelDraftState = {};

export const reducer = createReducer(initialState).handleAction(
  constants.SET_SIMULATION_MODEL_DRAFT,
  (state, action) => {
    return {
      ...state,
      currentDraft: action.payload,
    };
  },
);

export const getCurrentDraft = (state: StoreState): SimulationModelDraft | undefined => {
  return state.simulationModelDrafts.currentDraft;
};
