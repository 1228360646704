const strings = {
  "dataTapeStressStateLabel": "State",
  "dataTapeStressFieldLabel": "Field",
  "dataTapeStressTypeFieldLabel": "Stress type",
  "create_model_form_incomplete": "Not able to save form due to {0} being incomplete or invalid",
  "loanDataTapeUploadLabel": "Upload Loans",
  "securityDataTapeUploadLabel": "Upload Securities",
  "collateralDataTapeUploadLabel": "Upload Collaterals",
  "missingSalesDiscountsForNode": "No discount values provided for node {0} in loan file.",
  "csvToConfigErrorDictionaryKeysAndValuesLengthDifferentSalesPeriods":
    "Length of keys ({0}) and length of values ({1}) for salesPeriod in state ({2}) in segment ({3}) must be equal.",
  "periodsAndDiscountsDontMatch": "Number of sale periods and discounts don't match for state {0}.",
  "assetCollections": "Asset Collections",
  "addSalePeriodsLabel": "Add Sale Periods",
  "addStressButtonLabel": "Add Stress",
  "grossCollectionsLabel": "Gross collections",
  "netCollectionsLabel": "Net collections",
  "servicingCostsLabel": "Servicing costs",
  "salesPeriodsLabel": "Sale Periods",
  "intFloatDictKeyNegative": "Key can not be negative",
  "intFloatDictKeyNotValid": "Key must be an integer",
  "intFloatDictValueNotValid": "Value must be a number",
  "intFloatDictvalueButNotKeyProvided": "Must provide a key with each value",
  "intFloatDictkeyButNotValueProvided": "Must provide a value with each key",
  "cant_exceed_max_number_of_seats": "You have reached your limit of users on this subscription.",
  "addEditViewSubscriptionSeatsUsedLabel": "Seats Used",
  "invalideCsvOnlySupportSingleCategoryType": "We do not currently support multiple category types",
  "confirmDeactivateResultTitle": "Deactivate Result",
  "confirmDeactivateResultDescription": "Are you sure you want to deactivate this simulation result?",
  "confirmationDialogConfirmButtonText": "Confirm",
  "confirmationDialogCancelButtonText": "Cancel",
  "confirmDeactivateUserDescription": "This user will no longer be able to access the resources in your subscription.",
  "simuationResultsOverviewChartDataServicingCostsLabel": "Servicing Costs",
  "simuationResultsOverviewChartDataGrossCollectionsLabel": "Gross Collections",
  "confirmDeactivateUserTitle": "Are you sure want to deactivate this user?",
  "modelOverviewDownloadConfigLabelCsv": "Download Config - CSV Tapes",
  "modelOverviewDownloadConfigLabelXlsx": "Download Config - XLSX Tapes",
  "assetBalance": "Initial loan balance",
  "assetRev": "REV",
  "assetCrevDfas": "CREV DFAS",
  "assetCrevLiquidation": "CREV Liquidation",
  "assetCrev": "CREV",
  "assetLoansCount": "Number of loans",
  "assetGbvCollectionRate": "Balance collection rate",
  "assetCrevCollectionRate": "CREV collection rate",
  "notModelledBalance": "Not modelled balance",
  "initial": "Initial",
  "pre_law_3869": "Pre Law 3869",
  "post_law_3869": "Post Law 3869",
  "restructuring": "Restructuring",
  "liquidation": "Liquidation",
  "debtForAssetSwap": "Debt for asset swap",
  "preLaw3869": "Pre Law 3869",
  "simulationErrorsRemainingErrors": "Only showing {0} of {1} errors.",
  "postLaw3869": "Post Law 3869",
  "modelOverviewBusyDownloadingConfigLabel": "Downloading Config",
  "disposalRestructuring": "Disposal Restructuring",
  "missingIdInLoanData": "'id' is missing from loan data columns",
  "missingBalanceInLoanData": "'initialBalance' is missing from loan data columns",
  "missingSegmentIdInLoanData": "'segmentId' is missing from loan data columns",
  "modelOverviewLoansConfigSectionTitle": "Loans",
  "modelOverviewSecuritiesConfigSectionTitle": "Securities",
  "modelOverviewCollateralsConfigSectionTitle": "Collaterals",
  "uploadDataButtonText": "Upload",
  "uploadDataTapeButtonProcessingText": "Processing",
  "updateSegmentConfigTitle": "Update the loans segment configuration",
  "updateSecurityConfigTitle": "Update the security segment configuration",
  "updateCollateralConfigTitle": "Update the collateral segment configuration",
  "transformationKeysInputTitle": "Add Transformations",
  "availableFieldOnLoanFieldLabel": "Available fields on loan data",
  "uploadGraphButtonText": "Upload",
  "invalidJsonErrorMessage": "Json is not valid",
  "invalidJsonErrorMessageWithExplanation": "Json is not valid: {0}",
  "no_seats_remaining": "No more seats available on this subscription.",
  "role_not_found": "Role not found.",
  "insufficient_permissions": "You do not have sufficient permissions to carry out this request.",
  "invalid_subscription_for_user": "Invalid subscription.",
  "subscription_not_found": "Suscription not found.",
  "newModelLabel": "New Model",
  "dateAddedLabel": "Date Added",
  "numberOfLoansLabel": "Number of Loans",
  "totalBalanceLabel": "Total Portfolio Balance",
  "nodesInGraphLabel": "Nodes in graph",
  "selectOptionDialogSubmitButtonText": "Save",
  "selectOptionDialogCancelButtonText": "Cancel",
  "addToSubscriptionLabel": "Join subscription",
  "removeFromSubscriptionLabel": "Leave subscription",
  "deletingRolesLabel": "Deleting Role",
  "creatingRolesLabel": "Creating Role",
  "addTransfomrationsInputDescription":
    "Each Recovery State in your graph expects a certain set of parameters. In addition to these base parameters you can use the transformations below, to generate the expected parameters using parameters that may be more convenient for you.",
  "modelOverviewResultsTabLabel": "Results",
  "modelOverviewConfigurationTabLabel": "Configuration",
  "modelOverviewGeneralTabLabel": "General",
  "loanDataDashboarItemLabel": "Loan Data",
  "segmentsOverviewDashboarItemLabel": "Segments Overview",
  "loanDataDashboardItemInfoColumnTitle": "Info",
  "loanDataDashboardItemValueColumnTitle": "Value",
  "loanDataDashboarItemNumberOfLoans": "Number of loans",
  "loanDataDashboarItemOverallBalance": "Overall Balance",
  "loanDataDashboarItemCrevBalance": "CREV",
  "loanDataDashboarItemRevBalance": "REV",
  "loanDataDashboarItemNumberOfSegments": "Number of Segments",
  "modelOverviewPageSegmentOverviewSegmentName": "Segment",
  "modelOverviewPageSegmentOverviewRev": "REV",
  "modelOverviewPageSegmentOverviewCrev": "CREV",
  "modelOverviewPageSegmentOverviewBalance": "Initial Balance",
  "modelOverviewRunSimulation": "Run Simulation",
  "loanDataDashboardItemGraphLabel": "Recovery Graph",
  "simulationResultsTableCreatedAtTitle": "Created At",
  "simulationResultsTableCreatedByTitle": "Created By",
  "simulationResultsTableNameTitle": "Name",
  "loggingInPageBoxTitle": "Logging in...",
  "logutButtonLabel": "Logout",
  "simulationResultsTableNumberOfOverridesTitle": "Number Of Overrides",
  "uploadFromCsvLabel": ".csv",
  "uploadFromJsonLabel": ".json",
  "uploadConfigLabel": "Upload Config",
  "runSimulationScreen_saveResultsTitle": "Save Results",
  "runSimulationScreen_updateConfigTitle": "Update Loans Config",
  "runSimulationScreen_updateSecuritiesConfigTitle": "Update Securities Config",
  "runSimulationScreen_updateCollateralsConfigTitle": "Update Collaterals Config",
  "runSimulationScreen_chooseAnalyzersTitle": "Optional Analyses",
  "runSimulationScreen_chooseAnalyzersBlurb": "Note: These options may significantly increase execution time",
  "runSimulationScreen_dendrogramCheck": "Dendrogram",
  "runSimulationScreen_collectionsCheck": "Loan-by-loan collections",
  "runSimulationScreen_viewResultsTitle": "View Results",
  "saveSimulationResult_nameTextFieldLabel": "Name",
  "saveSimulationResult_descriptionTextFieldLabel": "Description",
  "simulationDetails_descriptionLabel": "Description",
  "saveSimulationResult_descriptionTextFieldErrorLabel": "Must contain a description less than {0} characters",
  "steppernextbutton_loading": "Loading...",
  "simulationResultsPage_noResultName": "No Name",
  "steppernextbutton_finished": "Finish",
  "steppernextbutton_next": "Next",
  "simulationDetails_createdLabel": "Created",
  "simulationResultPage_configurationSectionTitle": "Configuration",
  "simulationResultPage_resultsSectionTitle": "Results",
  "configurationSection_editTableButtonTitle": "Edit",
  "validation_error": "Validation Error",
  "json_parsing_error": "Json parsing error",
  "authentication_error": "Authentication Error",
  "generic_error": "Something went wrong",
  "server_error": "Server Error",
  "teamsPageDefaultDisplayName": "Teams",
  "simResultPageDefaultDisplayName": "Results",
  "modelOverviewDeactivateLabel": "Deactivate",
  "runSimResultPageDefaultDisplayName": "Run Simulation",
  "modelPageDefaultDisplayName": "Model",
  "createModelPageDefaultDisplayName": "Create Model",
  "modelsPageDefaultDisplayName": "Models",
  "teamsPageTitle": "All Teams",
  "confirmDeactivateModelTitle": "Deactivation Confirmation",
  "confirmDeactivateModelDescription": "Are you sure you want to deactivate this model?",
  "createTeamButtonTitle": "Create Team",
  "teamsTableNameTitle": "Name",
  "generateInviteLinkButtonLabel": "Generate Invite",
  "teamsTableNumberOfModelsTitle": "Models",
  "teamsTableNumberOfMembersTitle": "Members",
  "teamsTableDateAddedTitle": "Date Added",
  "addEditViewTeamPageTitle": "Team Details",
  "createTeamDisplayName": "Create Team",
  "teamDetailsDisplayName": "Team Details",
  "saveTeamButtonTitle": "Save",
  "closeInviteLinkDialogButtonText": "Got it",
  "inviteLinikDialogTitle": "Invite Link",
  "inviteLinikDialogDescription":
    "Share this link with the created user. Rember the token will not be valid and will expire after a few hours.",
  "textFieldMustBeDefinedReason": "{0} must be defined.",
  "textFieldMustNotBeEmpty": "{0} cannot be empty.",
  "usersPageDefaultDisplayName": "Users",
  "addEditViewTeamPageNameLabel": "Name",
  "teamDetailsGeneralSectionTitle": "General",
  "teamsButtonLabel": "Teams",
  "usersButtonLabel": "Users",
  "modelsButtonLabel": "Models",
  "subscriptionsButtonLabel": "Subscriptions",
  "teamDetailsModelsSectionTitle": "Models",
  "addEditViewTeamPageAddModelsButtonTitle": "Add Model",
  "resultsPageDownloadResultsButton": "Results",
  "resultsPageDownloadCollectionsNetButton": "Net Collections",
  "resultsPageDownloadCollectionsGrossButton": "Gross Collections",
  "searchableModelsDialogTitle": "Add Model",
  "searchableModelsDialogSearchBarLabel": "Model name",
  "searchableUsersDialogSearchBarLabel": "User name",
  "teamDetailsModelTableCreatedAtTitle": "Created At",
  "passwordTextFieldLabel": "Password",
  "confirmPasswordTextFieldLabel": "Confirm Password",
  "teamDetailsModelTableBalanceTitle": "Balance",
  "teamDetailsModelTableNumberOfLoansTitle": "Number of loans",
  "teamDetailsModelTableNameTitle": "Name",
  "teamDetailsUsersSectionTitle": "Members",
  "addEditViewTeamPageAddMembersButtonTitle": "Add Member",
  "teamDetailsMembersTableNameTitle": "Name",
  "teamDetailsMembersTableCreatedAtTitle": "Created At",
  "searchableUsersDialogTitle": "Add User",
  "usersTableDateAddedTitle": "Date Added",
  "usersTableRoleTitle": "Role",
  "usersTableEmailTitle": "Email",
  "usersTableNameTitle": "Name",
  "usersPageTitle": "All Users",
  "createUserButtonTitle": "Add User",
  "createUserDisplayName": "Create User",
  "userDetailsDisplayName": "User Details",
  "addEditViewUserPageTitle": "User Details",
  "userDetailsGeneralSectionTitle": "General",
  "addEditViewUserPageEmailLabel": "Email",
  "addEditViewUserPageNameLabel": "Name",
  "saveUserButtonTitle": "Save",
  "addEditViewUserPageRoleLabel": "Role",
  "notFoundText": "Page not found",
  "notFoundButtonLabel": "Go to home",
  "loginPageBoxTitle": "Credit Analytics Platform",
  "loginWithMicrosoft": "Log in with Microsoft",
  "setPasswordPageTitle": "Set your password",
  "passwordTooFewNumbersErrorMessage": "{0} contains too few numbers",
  "passwordTooFewLowerCaseErrorMessage": "{0} contains too few lower case letters",
  "periodsSliderLabel": "Periods",
  "failed_validation": "The parameters provided have been rejected by the server.",
  "dendrogramLegendOpeningBalance": "Opening Balance",
  "dendrogramLegendPrincipal": "Principal",
  "dendrogramLegendInterest": "Interest",
  "defaultedPathText": "Strategy Failure",
  "completedPathText": "Strategy Successful",
  "passwordTooFewUpperCaseCaseErrorMessage": "{0} contains too few upper case letters",
  "passwordTooShortErrorMessage": "{0} must be longer than 8 characters",
  "passwordsMustMatchErrorMessage": "Passwords must match",
  "submitPasswordButtonText": "Submit",
  "invalid_password_strength": "Password is too weak",
  "invitation_token_invalid": "Invitation id is not valid",
  "invitation_token_expired": "Invitation has expired",
  "loanDataMustIncludeAtLeastOneLoan": "Must include at least one loan",
  "orSeparatorLabelText": "or",
  "emailTextFieldLabel": "Email",
  "incorrect_login_details": "Those details don't match our records",
  "model_name_exists": "Model name is not unique.",
  "no workers": "No workers available.",
  "not_authorized": "Not authorized.",
  "not_authenticated": "Not authenticated.",
  "no_password_set": "No password set.",
  "users_not_found": "Users not found.",
  "displayNameKey_subscription-admin": "Subscription Admin",
  "displayNameKey_system-admin": "System Admin",
  "displayNameKey_user": "Normal User",
  "user_not_found": "User not found.",
  "team_not_found": "Team not found.",
  "unsupported_token_type": "Incorrect request details.",
  "resultTableCollectionsByStateTitle": "Gross Collections by State",
  "resultTableCollectionsBySegmentTitle": "Gross Collections by Segment",
  "resultTableOverviewTitle": "Overview",
  "resultTableOverallCollectionsTitle": "Gross Collections by period",
  "resultsSectionDendrogramTabTitleForBalances": "Dendrogram - Balances",
  "resultsSectionDendrogramTabTitleForCollections": "Dendrogram - P & I",
  "resultsCollectionsGraphByPeriodLabel": "Gross collections in the period",
  "resultsCollectionsCumulativeLabel": "Cumulative Gross collections",
  "modelOverviewModifyAndRun": "Modify and Run",
  "resulsOverallCollectionsDescription": "This portfolio generates {0}",
  "userGuidePageTitle": "ALANTRA ANALYTICS PLATFORM – USER GUIDE",
  "resultsSectionOverviewTabTitle": "Overview",
  "confirmInviteUserTitle": "Invite User",
  "confirmInviteUserDescription": "Inviting a user will send them an email with a link to set their password",
  "tableDoesNotMatchConfigurationErrorReason": "Table has {0} but config does not",
  "duplicateTransformationKeys": "The following keys ({0}) are duplicated",
  "table_does_not_match_configuration": "Table and underlying configuration does not match",
  "csvToConfigErrorDictionaryKeysAndValuesLengthDifferentStateParam":
    "Length of keys ({0}) and length of values ({1}) for param ({2}) in state ({3}) in segment ({4}) must be equal.",
  "csvToConfigErrorDictionaryKeysAndValuesLengthDifferentTransition":
    "Length of keys ({0}) and length of values ({1}) for {2} transition from ({3}) in segment ({4}) must be equal.",
  "fatalLifeCycleErrorMessage": "Fatal Error",
  "missingSegmentError": "The new configuration does not contain the following segment: {0}",
  "tooManySegmentsError":
    "The new configuration has segment {0} but this segment is not expected by the existing config",
  "transitionParamHasWrongFieldName":
    "The field name of transtitions must contain either 'normal' or 'defaulted' not {0}",
  "invalidRowData": "The row from the csv is not valid. {0}",
  "unexpectedSegment": "The new configuration has an unexpected segment ({0}). Expected one of ({1})",
  "unexpectedStateInSegment": "The new configuration has an unexpected state ({0}) in segment ({1})",
  "missingStateInSegment": "The new configuration has a missing state ({0}) in segment ({1})",
  "dataTapeUploadsTitle": "Upload data tapes",
  "unexpectedFieldInStateInSegment":
    "The new configuration has an unexpected field ({0}) in state ({1}) in segment ({2})",
  "missingFieldInStateInSegment": "The new configuration has a missing field ({0}) in state ({1}) in segment ({2})",
  "missingFieldInState": "The new configuration has a missing field ({0}) in state ({1})",
  "missingTransitionsErrorInSegment": "The new config has a missing {0} transition for state {1} in segment {2}",
  "missingTransitionsError": "The new config has a missing {0} transition from state {1} to state {2}",
  "missingTransitionsErrorFromCSV":
    "The new config has a missing {0} transition from state {1} to state {2}. To create a transition from a csv file use {0}TransitionStatesKeys and {0}TransitionStatesValues",
  "incompletePartialDictionaryStateParam":
    "Items ({0}) are missing in order to construct a dictionary for field ({0}) in state ({1}) in segment ({2}).",
  "errorNoBaseConfiguration": "There is no base configuration present to compare with",
  "incompletePartialDictionaryTransitionParam":
    "Items ({0}) are missing in order to construct a dictionary for {1} transition from state ({2}) in segment ({3}).",
  "unexpectedTransitionsError": "The new config has an unexpected {0} transition for state {1} in segment {2}",
  "unexpectedToStateTransitionsError":
    "The new config has an unexpected {0} transition from state {1} to state {2} in segment {3}. Must be one of ({4})",
  "generic_application_error": "Something went wrong.",
  "configsDontMatchErrorMessage": "Configs contain {0} differences, check logs.",
  "deleteTeamButtonTitle": "Delete",
  "addEditViewSubscriptionPageMaxNumberOfSeatsLabel": "Max number of seats",
  "saveSubscriptionButtonTitle": "Save",
  "deleteSubscriptionButtonTitle": "Delete",
  "addEditViewSubscriptionPageNameLabel": "Name",
  "textFieldMustBeInteger": "{0} must be an integer",
  "textFieldMustBeAboveValue": "{0} must be above {1}",
  "textFieldMustBeBelowValue": "{0} must be below {1}",
  "numberOfSeatsCantBeLessThanCurrentSeats": "Cannot specify a max number of seats less than current seats in use.",
  "addEditViewSubscriptionPageTitle": "Subscriptions",
  "subscriptionDetailsGeneralSectionTitle": "General",
  "deleteUserButtonTitle": "Deactivate",
  "userHasNoModelsHelpText": "You do not have access to any models. Ask an admin to add you to a team with models.",
  "integerInvalidText": "Value must be an integer",
  "nonZeroIntegerInvalidTextNotInteger": "Value must be an integer",
  "na": "N/A",
  "nonZeroIntegerInvalidTextNotGreaterThanZero": "Value must be greater than 0",
  "confirmDeactivateSubscriptionTitle": "Are you sure?",
  "confirmDeactivateSubscriptionDescription":
    "Deleting a subscription may have immediate effect on the users of that subscription and cannot be undone.",
  "createSubscriptionDisplayName": "Create Subscription",
  "subscriptionDetailsDisplayName": "Subscription Details",
  "addEditViewSubscriptionPageInfinitePlacholder": "Infinite",
  "setActiveSubscriptionButton": "Set Active Subscription",
  "probabilityInvalidText": "Value must be between 0 and 1",
  "enumInvalidText": "Value must be selected from dropdown options.",
  "fieldTypesDontMatch":
    "The types in field ({0}) don't match for state ({1}) in segment ({2}). Expected {3}, but got {4}",
  "termsAndConditionsPageTitle": "ALANTRA CREDIT ANALYTICS PLATFORM ACCEPTABLE USE POLICY(AUP)",
  "privacyPageTitle": "ALANTRA CREDIT ANALYTICS PLATFORM PRIVACY NOTICE",
  "setActiveSubscriptionDialogDescription":
    "All future actions will happen within the context of the subscription you select.",
  "setActiveSubscriptionDialogTitle": "Set Active Subscription",
  "privacyFooterLabel": "Privacy",
  "termsFooterLabel": "Terms",
  "acceptTermsDialogTitle": "Accept Terms and Conditions",
  "acceptTermsdialgoDescription": "Before you can continue please read and accept our terms of service:",
  "acceptTermsAcceptButton": "Accept",
  "acceptTermsCancelButton": "Cancel",
  "errorUploadingConfigTitle": "There were some errors uploading your config",
  "errorUploadingConfigDescription":
    "There were {0} mismatches between the config you have uploaded and the expected config. You can 'Soft Merge' the fields that matched. The mismatched fields will be ignored.",
  "closeErrorUploadingConfigDialogButtonText": "Close",
  "errorUploadingConfigDialogMergeAnywayButtonText": "Merge anyway",
  "must_accept_terms": "You must first accept our Terms and Conditions",
  "non_alantra_admin": "Only Alantra users can be made Admin",
  "missing_parameter": "The input paramaters were rejected by the server.",
  "execution_failed": "Running this model failed",
  "weightedAverageTitle": "WA Collection Period (y)",
  "priceGivenIRR": "Discounted Net Collections",
  "irrTextfieldLabel": "IRR (Annual)",
  "bad_email": "The email is not valid",
  "bad_data": "The input paramaters were rejected by the server.",
  "account_locked": "Your account has been locked. Contact Support.",
  "state_id_not_found": "The graph node types are not valid",
  "simulation_result_not_found": "The simulation result was not found.",
  "simulation_not_found": "The simulation result was not found ",
  "models_not_found": "No models were found",
  "model_not_found": "The model was not found",
  "invite_not_found": "The invite was not found. ",
  "closeStaticCellInfoDialog": "Got it",
  "generatingSimulationText": "Generating results...",
  "contactUsFooterLabel": "Contact Us",
  "contactUsAccountsDropDownLabel": "Contact Us",
  "userGuideAccountsDropDownLabel": "User Guide",
  "technicalGuideAccountsDropDownLabel": "Technical Guide",
  "technicalGuidePageTitle": "ALANTRA ANALYTICS PLATFORM – TECHNICAL GUIDE",
  "contactUsEmailSubjectWithUserId": "User support query [{0}]",
  "contactUsEmailSubject": "User support query",
  "simulationResultsTableExecutionstateTitle": "Status",
  "usersTableActionTitle": "Actions",
  "usersPageInviteButtonLabel": "Invite",
  "cannot_send_invite_email_to_user": "Cannot send an invite to a user who has already been invited",
  "simulationResultsTableNoNamePlaceholder": "",
  "editableTableDownloadConfigButton": "Download CSV",
  "editableTableUploadConfigButton": "Upload CSV",
  "simulationResultPageGenericResultName": "result",
  "modelOverviewGenericModelName": "model",
  "modelOverviewConfigDownloadName": "{0}-base",
  "runSimulationDownloadConfigName": "{0}-no-name",
  "userNotRegisteredTitle": "You are not registered to use this application",
  "updateTextDialogSubmitButtonText": "Submit",
  "updateTextDialogCancelButtonText": "Cancel",
  "pageUpdateNameSettingsButton": "Rename",
  "pageRenameSettingsButtonLabel": "Rename",
  "pageUpdateNameTextFieldLabel": "Name",
  "modelListScreenSearchPlaceholder": "Search Models",
  "userlListScreenSearchPlaceholder": "Search Users",
  "modelsListPageSortByDropDownLabel": "Sort By",
  "simulationModelInitialBalanceDisplayValue": "Initial Balance",
  "simulationModelNumberOfLoansDisplayValue": "Number Of Loans",
  "loansConfigurationTitle": "Loans Configuration",
  "loansStressesTableTitle": "Loans Datatape stresses",
  "securitiesConfigurationTitle": "Securities Configuration",
  "noConfigLabel": "No configuration provided",
  "noStressesLabel": "No stresses provided",
  "securitiesStressesTableTitle": "Securities Datatape stresses",
  "collateralsConfigurationTitle": "Collaterals Configuration",
  "collateralsStressesTableTitle": "Collaterals Datatape stresses",
  "simulationModelCreatedAtDisplayValue": "Date Created",
  "simulationModelNameDisplayValue": "Name",
  "simulationResultsPageDefaultTitle": "Simulation Results",
  "simulationResultsPageDeactivateLabel": "Deactivate",
  "collectionsChartXAxisLabel": "Periods (m)",
  "resultTableOverviewChartTitle": "Realisable Collateral Value",
  "simuationResultsOverviewChartDataBalanceLabel": "Outstanding Balance",
  "simuationResultsOverviewChartDataNetCollectionsLabel": "Net Collections",
  "simuationResultsOverviewChartDataCrevLabel": "CREV",
  "simuationResultsOverviewChartDataRevLabel": "REV",
  "simuationResultsOverviewChartDataCrevProceedsLabel": "Recovery CREV",
  "simulationResultsRunTimeSecs": "Calculation time",
  "createModelClearCacheLabel": "Clear",
  "simulationPageTruncatedChartsNote": "Truncated to 99% total collections",
  "rangeCellValidationMustBeInteger": "Must be an integer",
  "rangeCellValidationToLow": "Must be greater than {0}",
  "percentageCellValidationToLow": "Must be greater than {0}%",
  "notValidPercentageValue": "Not a valid percentage value",
  "notValidNumberValue": "Not a valid number",
  "rangeCellValidationToLowInclusive": "Must be greater than or equal to {0}",
  "percentageCellValidationToLowInclusive": "Must be greater than or equal to {0}%",
  "rangeCellValidationToHigh": "Must be less than {0}",
  "percentageCellValidationToHigh": "Must be less than {0}%",
  "createModelSaveButton": "Save",
  "createModelPleaseUploadALoanFile": "Loan file required",
  "createModelCachedDataLabel": "Unsaved changes from {0}",
  "createModelApplyCacheLabel": "Apply",
  "rangeCellValidationToHighInclusive": "Must be less than or equal to {0}",
  "percentageCellValidationToHighInclusive": "Must be less than or equal to {0}%",
  "rangeCellValidationOutOfStepWithMin": "Must be greater than {0} and increase in increments of {1}",
  "rangeCellValidationOutOfStepWithMinInclusive":
    "Must be greater than or equal to {0} and increase in increments of {1}",
  "simulationErrorsRunFailedTitle": "There were errors during the simulation run:",
  "simulationErrorsShowDetailsLinkTitle": "Show details",
  "simulationErrorsHideDetailsLinkTitle": "Hide details",
  "simulationErrorsExtraDataTitle": "Context",
  "simulationErrorsMessageTitle": "Message",
  "simulationErrorsErrorKeyTitle": "Error Key",
  "rangeCellValidationOutOfStep": "Must increase in increments of {0}",
  "simulationResultsPageExecutionStatefailed": "The simulation run has failed.",
  "simulationResultsPageExecutionStatestalled": "The simulation run has stalled.",
  "simulationResultsPageExecutionStateinitialized": "The simulation run is still being executed.",
  "simulationResultsPageExecutionStatestarted": "The simulation run is still being executed.",
  "rangeCellValidationOutOfStepWithMax": "Must be less than {0} and decrease in increments of {1}",
  "rangeCellValidationOutOfStepWithMaxInclusive": "Must be less than or equal to {0} and decrease in increments of {1}",
  "mustBeANumberErrorMessage": "Must be number",
  "userNotRegisteredDescription":
    "Please get in touch with your Alantra deal team or contact [analytics.support@alantra.com](mailto:analytics.support@alantra.com) if you are having trouble logging in.",
  "fetchingSimeulationResultText": "Fetching Results",
  "tableOfContentsTitle": "Contents",
  "transformationKeysDisplayName_default": "No Transformation",
  "testPrefix_testKey": "Do Not Change Me",
  "teamDetailsMembersTableEmailTitle": "Email",
  "uploadTransformationKeysLabel": "Upload",
  "transformationKeyFileInvalid":
    "The csv data uploaded for the transformation keys are not valid. Expected a csv file with two columns where the first column is the state id and the second is the transformation key. Column headers are not expected.",
  "invalid_transformation_key_file": "Transformation key file not valid",
  "invalidTransformationKeys_invalidKey": "Key ({0}) for state ({1}) is not in list ({2})",
  "invalidTransformationKeys_invalidNodeIds": "State ids ({0}) are not found in expected ids ({1})",
  "usersTableInvitationStatusTitle": "Invitation status",
  "assetCrevProceeds": "CREV proceeds",
  "resultsOverviewAmountLabel": "Amount",

  "simResultPageSegmentOverviewSegmentInitialCollectionsCrevOverCollections": "Collections / CREV (%)",
  "simResultPageSegmentOverviewSegmentInitialCollectionsCrev": "CREV",
  "simResultPageSegmentOverviewSegmentInitialCollectionsRevOverCollections": "Collections / REV (%)",
  "simResultPageSegmentOverviewSegmentInitialCollectionsRev": "REV",
  "simResultPageSegmentOverviewSegmentInitialCollectionsOverBalance": "Gross Collections / Balance (%)",
  "simResultPageSegmentOverviewSegmentInitialBalance": "Balance",
  "simResultPageSegmentOverviewSegmentWAL": "WA Collection Period",
  "simResultPageSegmentOverviewCollections": "Gross Collections",
  "simResultPageSegmentOverviewSegmentName": "Segments",

  "subscriptionsPageDefaultDisplayName": "Subscriptions",
  "subscriptionsPageTitle": "Subscriptions",
  "createSubscriptionButtonTitle": "Add subscription",
  "subscriptionsTableNameTitle": "Name",
  "subscriptionsTableSeatsUsedTitle": "Seats Used",
  "subscriptionsTableSeatsAvailableTitle": "Seats Available",
  "subscriptionsTableDateAddedTitle": "Date Added",
};

export default strings;
