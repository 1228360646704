import { UPDATE_SIMULATION_RESULT, USER_LOGOUT, DELETE_SIMULATION_RESULT } from "../../constants/index";
import { StoreState } from "types/store";
import { SimulationResultsState } from "types/store";
import { createReducer } from "typesafe-actions";
import { SimulationResult } from "store/models/simulationResult";

// For more details on the design of this reducer see docs/redux_store_design.md#resource_storage
export const initialState: SimulationResultsState = {
  items: {},
  lists: {},
};

export const MAX_RESULTS = 20;

export const reducer = createReducer(initialState)
  .handleAction(USER_LOGOUT, () => {
    return initialState;
  })
  .handleAction(UPDATE_SIMULATION_RESULT, (state, action) => {
    let remainingItems: Record<string, SimulationResult> | undefined;

    // The result objects are quite big so we want a ceiling on the number of items in the store.
    if (Object.keys(state.items).length === MAX_RESULTS) {
      const sortedItems = Object.values(state.items).sort((a, b) => {
        return +a.createdAt - +b.createdAt;
      });
      remainingItems = sortedItems.slice(1, MAX_RESULTS).reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {});
    }
    return {
      ...state,
      items: {
        ...(remainingItems || state.items),
        [action.payload.id]: action.payload,
      },
    };
  })
  .handleAction(DELETE_SIMULATION_RESULT, (state, action) => {
    const { [action.payload]: value, ...remainingItems } = state.items; //eslint-disable-line no-unused-vars, @typescript-eslint/no-unused-vars
    return {
      ...state,
      items: remainingItems,
    };
  });

export const getSimulationResult = (state: StoreState, resultId: string): SimulationResult =>
  state.simulationResults.items[resultId];
