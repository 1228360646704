interface Config {
  inactivityTimeOut: number;
  apiHost: string;
  frontendHost: string;
  sentryDsn: string;
  sentryEnv: string;
}

const baseConfig = {
  inactivityTimeOut: 1000 * 60 * 30,
  sentryDsn: "https://d531f02b5414452487313701c773c806@o346127.ingest.sentry.io/5200930",
};

const envs: Record<string, Config> = {
  local: {
    ...baseConfig,
    apiHost: "http://localhost:8000/v1",
    frontendHost: "http://localhost:3000",
    sentryDsn: "",
    sentryEnv: "",
  },
  test: {
    ...baseConfig,
    apiHost: "http://localhost:8000/v1",
    frontendHost: "http://localhost:3000",
    inactivityTimeOut: 2000,
    sentryDsn: "",
    sentryEnv: "",
  },
  development: {
    ...baseConfig,
    apiHost: "https://dev.alantra-analytics.com/api/v1",
    frontendHost: "https://dev.alantra-analytics.com",
    sentryEnv: "development",
  },
  staging: {
    ...baseConfig,
    apiHost: "https://stg.alantra-analytics.com/api/v1",
    frontendHost: "https://stg.alantra-analytics.com",
    sentryEnv: "staging",
  },
  production: {
    ...baseConfig,
    apiHost: "https://analytics.alantra.com/api/v1",
    frontendHost: "https://analytics.alantra.com",
    sentryEnv: "production",
  },
  features: {
    ...baseConfig,
    apiHost: "https://features.alantra-analytics.com/api/v1",
    frontendHost: "https://features.alantra-analytics.com",
    sentryDsn: "",
    sentryEnv: "features",
  },
};

export const env = envs[process.env.REACT_APP_ENV || "local"];
