import {
  UPDATE_SIMULATION_MODEL,
  DELETE_SIMULATION_MODEL,
  USER_LOGOUT,
  UPDATE_SIMULATION_MODEL_SUMMARY,
} from "../../constants/index";
import { AllActions, StoreState } from "types/store";
import { SimulationModelsState } from "types/store";
import { SimulationModel } from "store/models/simulationModel";
import { createReducer } from "typesafe-actions";

// For more details on the design of this reducer see docs/redux_store_design.md#resource_storage
export const initialState: SimulationModelsState = {
  items: {},
  lists: {},
};

declare module "typesafe-actions" {
  interface Types {
    RootAction: AllActions;
  }
}

export const reducer = createReducer(initialState)
  .handleAction(USER_LOGOUT, () => {
    return initialState;
  })
  .handleAction(UPDATE_SIMULATION_MODEL, (state, action) => {
    return {
      ...state,
      items: {
        ...state.items,
        [action.payload.id]: action.payload,
      },
    };
  })
  .handleAction(UPDATE_SIMULATION_MODEL_SUMMARY, (state, action) => {
    const id = action.payload.id;
    const currentModel: SimulationModel = state.items[id];
    const updateModel: SimulationModel | undefined = currentModel
      ? {
          ...currentModel,
          ...action.payload,
        }
      : undefined;
    if (updateModel) {
      return {
        ...state,
        items: {
          ...state.items,
          [action.payload.id]: updateModel,
        },
      };
    }
    return state;
  })
  .handleAction(DELETE_SIMULATION_MODEL, (state, action) => {
    const { [action.payload]: value, ...remainingItems } = state.items; //eslint-disable-line no-unused-vars, @typescript-eslint/no-unused-vars
    return {
      ...state,
      items: remainingItems,
    };
  });

export const getSimulationModel = (state: StoreState, modelId: string): SimulationModel =>
  state.simulationModels.items[modelId];
