import { UPDATE_STATE_PARAMS, USER_LOGOUT } from "../../constants/index";
import { createReducer } from "typesafe-actions";
import { StateParamsState } from "types/store";
import { StateParams } from "store/models/stateParams";
import hash from "object-hash";

export const initialState: StateParamsState = {
  items: {},
};

export const reducer = createReducer(initialState)
  .handleAction(USER_LOGOUT, () => {
    return initialState;
  })
  .handleAction(UPDATE_STATE_PARAMS, (state, action) => {
    return {
      ...state,
      items: {
        ...state.items,
        [action.payload.id]: action.payload.params,
      },
    };
  });

export const stateParamsForStates = (state: StateParamsState, stateIds: string[]): StateParams | void => {
  const stateIdsHash = hash(new Set(stateIds));
  return state.items[stateIdsHash];
};
