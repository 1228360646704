import React, { ReactElement } from "react";
import "./Loader.scss";
import { Box, Typography } from "@material-ui/core";
import { isIE } from "utils/responsive";

const Loader = (): ReactElement => {
  return (
    <>
      {isIE && <Typography>Loading</Typography>}
      {!isIE && (
        <Box my={1} data-test-id="loader" data-testid="loader">
          <svg className="spinner" width="40px" height="40px" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
            <circle className="path" fill="none" strokeWidth="4" strokeLinecap="round" cx="20" cy="20" r="18"></circle>
          </svg>
        </Box>
      )}
    </>
  );
};

export default Loader;
