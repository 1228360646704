import { createReducer } from "typesafe-actions";
import { UsersState } from "types/store";
import { DELETE_USER, UPDATE_USER, UPDATE_USER_LIST, USER_LOGOUT } from "../../constants/index";
import { User } from "store/models/users";

// For more details on the design of this reducer see docs/redux_store_design.md#resource_storage
export const initialState: UsersState = {
  items: {},
  lists: {},
};

export const reducer = createReducer(initialState)
  .handleAction(USER_LOGOUT, () => {
    return initialState;
  })
  .handleAction(DELETE_USER, (state, action) => {
    const { [action.payload]: deletedUser, ...otherUsers } = state.items; // eslint-disable-line @typescript-eslint/no-unused-vars
    return {
      ...state,
      items: otherUsers,
    };
  })
  .handleAction(UPDATE_USER, (state, action) => {
    return {
      ...state,
      items: {
        ...state.items,
        [action.payload.id]: action.payload,
      },
    };
  })
  .handleAction(UPDATE_USER_LIST, (state, action) => {
    return {
      ...state,
      lists: {
        ...state.lists,
        [action.payload.listId]: {
          items: action.payload.items.map((user) => user.id),
          fetchedAt: new Date(Date.now()),
        },
      },
      items: {
        ...state.items,
        ...action.payload.items.reduce((acc, user) => ({ ...acc, [user.id]: user }), {} as Record<string, User>),
      },
    };
  });

// Store accessors

export const getUser = (state: UsersState, userId: string): User | void => {
  return state.items[userId];
};

export const getUsers = (state: UsersState, listId: string): User[] | void => {
  const list = state.lists[listId];
  if (list) {
    return list.items.map((itemId) => state.items[itemId]);
  }
};

export const searchUsers = (state: UsersState, query: string, limit: number): User[] | void => {
  return Object.values(state.items)
    .filter((user) => user.name.startsWith(query))
    .slice(0, limit);
};
