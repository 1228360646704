import { withSubscriptionId, ThunkResponse } from "./../../utils/api";
import { RoleEnum } from "./../models/users";
import {
  DeleteRoleAction,
  UpdateRoleAction,
  ApiRequestAction,
  ApiRequstCallbackAction,
  ApiRequestMeta,
} from "./../../types/store";
import * as constants from "../../constants";
import { Role, RolesValidator } from "store/models/users";
import { generateRequestId } from "utils/api";
import { apiRequest } from "./api";

export function updateRole(role: Role): UpdateRoleAction {
  return {
    type: constants.UPDATE_ROLE,
    payload: role,
  };
}

export function deleteRole(id: string): DeleteRoleAction {
  return {
    type: constants.DELETE_ROLE,
    payload: id,
  };
}

export const DELETE_ROLE_REQUEST_ID = "deleteRoleRequest";
export const deleteRoleRequest = (
  subscriptionId: string,
  roleId: string,
  onComplete?: () => void,
): ApiRequestAction<unknown> => {
  const onSuccess: ApiRequstCallbackAction<unknown> = () => (): void => {
    if (onComplete) {
      onComplete();
    }
  };
  const requestMeta: ApiRequestMeta<unknown> = {
    onSuccessAction: onSuccess,
    onErrorAction: () => (): void => {},
    url: `/subscription/${subscriptionId}/role/${roleId}`,
    method: "DELETE",
    headers: {},
    id: generateRequestId(DELETE_ROLE_REQUEST_ID, roleId),
  };
  return apiRequest(requestMeta);
};

export interface CreateRolePayload {
  userId: string;
  role: RoleEnum;
}

export const CREATE_ROLE_REQUEST_ID = "createRolesRequest";
export const createRole = (
  subscriptionId: string,
  createRolePayload: CreateRolePayload,
  createdId?: (id: string) => void,
): ApiRequestAction<Role> => {
  const onSuccess: ApiRequstCallbackAction<Role> = (result) => (dispatch): void => {
    dispatch(updateRole(result));
    if (createdId) {
      createdId(result.id);
    }
  };
  const requestMeta: ApiRequestMeta<Role> = {
    validator: RolesValidator,
    onSuccessAction: onSuccess,
    onErrorAction: () => (): void => {},
    url: `/subscription/${subscriptionId}/roles`,
    method: "POST",
    body: createRolePayload,
    headers: {},
    id: generateRequestId(CREATE_ROLE_REQUEST_ID, ""),
  };
  return apiRequest(requestMeta);
};

export const FETCH_ROLE_REQUEST_ID = "getRolesRequest";
export const fetchRole = (roleId: string): ThunkResponse<Role> => {
  const onSuccess: ApiRequstCallbackAction<Role> = (result) => (dispatch): void => {
    dispatch(updateRole(result));
  };
  return withSubscriptionId((subscriptionId) => {
    const requestMeta: ApiRequestMeta<Role> = {
      validator: RolesValidator,
      onSuccessAction: onSuccess,
      onErrorAction: () => (): void => {},
      url: `/subscription/${subscriptionId}/role/${roleId}`,
      method: "GET",
      headers: {},
      id: generateRequestId(FETCH_ROLE_REQUEST_ID, roleId),
    };
    return apiRequest(requestMeta);
  });
};
