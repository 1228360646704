import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { reducer as simulationModelReducer } from "store/reducers/simulationModels";
import { reducer as simulationModelSummariesReducer } from "store/reducers/simulationModelSummaries";
import { reducer as simulationResultsReducer } from "store/reducers/simulationResults";
import { reducer as simulationResultSummariesReducer } from "store/reducers/simulationResultSummaries";
import { reducer as apiRequestReducer } from "store/reducers/apiRequests";
import { reducer as navigationReducer } from "store/reducers/navigation";
import { reducer as stateParamsReducer } from "store/reducers/stateParams";
import { reducer as usersReducer } from "store/reducers/users";
import { reducer as teamsReducer } from "store/reducers/teams";
import { reducer as subscriptionsReducer } from "store/reducers/subscriptions";
import { reducer as currentuserReducer } from "store/reducers/currentUser";
import { reducer as simulationModelDraftsReducer } from "store/reducers/simulationModelDrafts";
import { StoreState, StoreActions } from "types/store";
import thunk from "redux-thunk";
import apiMiddleware from "./middleware/api";
import mockedRequests from "./middleware/mockedRequests";
import { save, load } from "redux-localstorage-simple";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose; //eslint-disable-line
  }
}

const composeEnhancers =
  process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const reducers = combineReducers<StoreState>({
  simulationModelDrafts: simulationModelDraftsReducer,
  simulationResults: simulationResultsReducer,
  simulationResultSummaries: simulationResultSummariesReducer,
  simulationModels: simulationModelReducer,
  simulationModelSummaries: simulationModelSummariesReducer,
  stateParams: stateParamsReducer,
  apiRequests: apiRequestReducer,
  navigation: navigationReducer,
  users: usersReducer,
  teams: teamsReducer,
  subscriptions: subscriptionsReducer,
  currentUser: currentuserReducer,
});

const reduxLocalStorageNamspace = "1";

// Temporary cleanup
// This can be removed once this code has been in production for reasonable amount of time.
localStorage.removeItem("redux_localstorage_simple_currentUser");

export const store = createStore<StoreState, StoreActions, unknown, null>(
  reducers,
  // This load function will load the initial state for the currentUser reducer from localstorage
  load({ namespace: reduxLocalStorageNamspace, states: ["currentUser"] }) as StoreState,
  // The 'save' middleware will hook into all events destined for the 'curentUser' reducer and additionaly update
  // localstorage with the state of the reducer
  composeEnhancers(
    applyMiddleware(
      thunk,
      apiMiddleware(mockedRequests),
      save({ namespace: reduxLocalStorageNamspace, states: ["currentUser"] }),
    ),
  ),
);
