import React, { ReactElement } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  makeStyles,
  createStyles,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { strings } from "content";
import CircularProgress from "@material-ui/core/CircularProgress";

export interface Props<Option> {
  open: boolean;
  onClose: () => void;
  title: string;
  description?: string;
  optionSelected: (option: Option) => void;
  options: Option[];
  fetchOptions: () => void;
  optionToDisplayName: (option: Option) => string;
  optionToId: (option: Option) => string;
  optionFromId: (id: string, options: Option[]) => Option;
  initialOption?: Option;
  submitActionLoading?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: "200px",
    },
  }),
);

const SelectOptionDialog = <Option extends {}>(props: Props<Option>): ReactElement | null => {
  const {
    open,
    fetchOptions,
    optionToDisplayName,
    optionToId,
    optionFromId,
    title,
    onClose,
    description,
    submitActionLoading,
    options,
  } = props;
  const { initialOption } = props;
  const [currentOption, setCurrentOption] = React.useState<Option | undefined>();
  const selectValue = currentOption ? optionToId(currentOption) : initialOption ? optionToId(initialOption) : null;

  React.useEffect(() => {
    fetchOptions();
  }, []);
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={(): void => {
        if (!submitActionLoading) {
          onClose();
        }
      }}
      fullWidth={true}
      maxWidth={"sm"}
      data-testid="update-text-dialog"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {description && <DialogContentText>{description}</DialogContentText>}
        <FormControl className={classes.formControl}>
          <Select
            value={selectValue}
            onChange={(event: React.ChangeEvent<{ value: unknown }>): void =>
              setCurrentOption(optionFromId(event.target.value as string, options))
            }
          >
            {options.map((option) => {
              return (
                <MenuItem key={optionToDisplayName(option)} value={optionToId(option)}>
                  {optionToDisplayName(option)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={submitActionLoading}
          onClick={onClose}
          color="primary"
          data-testid="update-text-cancel-button"
        >
          {strings.selectOptionDialogCancelButtonText}
        </Button>
        <Button
          variant="contained"
          onClick={(): void => {
            if (currentOption) {
              props.optionSelected(currentOption);
            }
          }}
          color="primary"
          data-testid="update-text-submit-button"
          disabled={submitActionLoading}
          endIcon={submitActionLoading ? <CircularProgress color="inherit" size={20} /> : undefined}
        >
          {strings.selectOptionDialogSubmitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectOptionDialog;
