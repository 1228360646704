import * as t from "io-ts";
import { either } from "fp-ts/lib/Either";

export const DateType = new t.Type<Date, string, unknown>(
  "DateFromString",
  (date): date is Date => date instanceof Date,
  (dateString, context) =>
    either.chain(t.string.validate(dateString, context), (str) => {
      const date = new Date(str);
      return isNaN(date.getTime()) ? t.failure(dateString, context) : t.success(date);
    }),
  (date) => date.toISOString(),
);
