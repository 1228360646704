import React, { ReactNode } from "react";
import "styles/page.scss";
import { Paper, Box, makeStyles, createStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => createStyles({}));

export interface OwnProps {
  title: string;
  className?: string;
  children?: ReactNode;
}

interface StateProps {}

interface DispatchProps {}

type Props = StateProps & DispatchProps & OwnProps;

const PlainInputSection: React.FC<Props> = (props) => {
  return (
    <Paper className={[props.className].join(" ")}>
      <Box p={2} display="flex" flexDirection="column" my={2}>
        <Box fontSize="h6.fontSize" fontWeight="500">
          {props.title}
        </Box>
        <Box>{props.children || null}</Box>
      </Box>
    </Paper>
  );
};

export default PlainInputSection;
