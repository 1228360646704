import React from "react";
import "styles/page.scss";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Link } from "@material-ui/core";
import { ReactComponent as Logo } from "assets/alantraLogo.svg";
import { strings } from "content";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { mailToString, decodedEmail, generateEmailSubject } from "utils/links";
import { StoreState } from "types/store";
import { getCurrentUser } from "store/reducers/currentUser";
import { connect } from "react-redux";

interface StoreProps {
  currentUserId?: string;
}

export interface Props extends RouteComponentProps, StoreProps {}

const useStyles = makeStyles((theme) => {
  return createStyles({
    outerPage: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      minHeight: "100vh",
    },
    content: {
      flex: "1 0 0",
      flexGrow: 1,
    },
    line: {
      height: 1,
      backgroundColor: theme.palette.grey[400],
      borderRadius: 1,
      flexGrow: 1,
      margin: theme.spacing(2),
      width: 80,
      maxWidth: 100,
    },
    footer: {
      bottom: 0,
      width: "100%",
      flexShrink: 0,
    },
    logo: {
      width: "100px",
      maxHeight: "20px",
      "& path": {
        fill: theme.palette.grey[400],
      },
    },
    separator: {
      display: "flex",
      alignItems: "center",
    },
    footerContent: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    sideItems: {
      flexGrow: 2,
      flexBasis: "130px",
    },
    centerItem: {
      flexGrow: 0,
    },
    button: {
      textTransform: "capitalize",
      margin: theme.spacing(1),
      fontSize: 12,
    },
  });
});

const Footer: React.FC<Props> = (props) => {
  const classes = useStyles();

  return (
    <Box display="flex">
      <Box className={classes.outerPage}>
        <Box className={classes.content}>{props.children}</Box>
        <Box mt={4} data-test-id="footer" className={classes.footer}>
          {/* Extra wrapper is here because IE11 handles justify-content weirdly */}
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box className={classes.separator}>
              <div className={classes.line}></div>
              <Logo className={classes.logo}></Logo>
              <div className={classes.line}></div>
            </Box>
          </Box>
          <Box mx={2} mb={1} className={classes.footerContent}>
            <Typography
              data-test-id="copywrite-label"
              color="textSecondary"
              className={classes.sideItems}
              variant="caption"
            >
              {`© Copyright ${new Date(Date.now()).getFullYear()}`}
            </Typography>
            <Box className={classes.centerItem}>
              <Link
                component="button"
                className={classes.button}
                color="primary"
                onClick={(): void => props.history.push("/terms")}
                data-test-id="terms-footer-button"
              >
                {strings.termsFooterLabel}
              </Link>
              <Link
                component="button"
                className={classes.button}
                color="primary"
                onClick={(): void => props.history.push("/privacy")}
                data-test-id="privacy-footer-button"
              >
                {strings.privacyFooterLabel}
              </Link>
              <Link
                component="button"
                className={classes.button}
                color="primary"
                onClick={(): void => {
                  window.location.href = mailToString({
                    email: decodedEmail,
                    subject: generateEmailSubject(props.currentUserId),
                  });
                }}
                data-test-id="contactUs-footer-button"
              >
                {strings.contactUsFooterLabel}
              </Link>
            </Box>
            <Box className={classes.sideItems}></Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: StoreState): StoreProps => {
  return {
    currentUserId: (getCurrentUser(state) || {}).id,
  };
};

export const FooterWithRouter = withRouter(Footer);

export default connect(mapStateToProps)(FooterWithRouter);
