import { lazy } from "react";
import { buildNavTree, NavItem, NavTreeItem, StaticPage } from "utils/navigation";
import { getContent } from "content";
import ConnectedAddEditViewSubscription from "pages/Subscriptions/AddEditViewSubscriptions";

const ModelsList = lazy(() => import("pages/ModelsList/ModelsList"));
const ModelOverview = lazy(() => import("pages/ModelOverview/ModelOverview"));
const RunSimulation = lazy(() => import("pages/RunSimulation/RunSimulation"));
const CreateModel = lazy(() => import("pages/CreateModel/CreateModel"));
const SimulationResultPage = lazy(() => import("pages/SimulationResultPage/SimulationResultPage"));
const ConnectedTeamsPage = lazy(() => import("pages/Teams/TeamsPage"));
const ConnectedAddEditViewTeam = lazy(() => import("pages/Teams/AddEditViewTeam"));
const ConnectedUsersPage = lazy(() => import("pages/Users/UsersPage"));
const ConnectedAddEditViewUser = lazy(() => import("pages/Users/AddEditViewUser"));
const ConnectedLoginPage = lazy(() => import("pages/Auth/Login"));
const Authenticated = lazy(() => import("pages/Auth/Authenticated"));
const ConnectedSetPasswordPage = lazy(() => import("pages/Auth/SetPassword"));
const ConnectedLanding = lazy(() => import("pages/Landing"));
const StatementPage = lazy(() => import("pages/StatementPage"));
const TermsPage = lazy(() => import("pages/Static/TermsPage"));
const PrivacyPage = lazy(() => import("pages/Static/PrivacyPage"));
const UserGuidePage = lazy(() => import("pages/Static/UserGuidePage"));
const TechnicalGuidePage = lazy(() => import("pages/Static/TechnicalGuidePage"));
const NotFoundPage = lazy(() => import("pages/NotFoundPage"));
const ConnectedSubscriptionsPage = lazy(() => import("pages/Subscriptions/SubscriptionsPage"));

const navigationTree: NavTreeItem[] = [
  {
    path: "/models",
    id: "model",
    defaultDisplayName: getContent("modelsPageDefaultDisplayName"),
    component: ModelsList,
    children: [
      {
        path: "/create_model",
        id: "create_model",
        defaultDisplayName: getContent("createModelPageDefaultDisplayName"),
        component: CreateModel,
        children: [],
      },
      {
        path: "/models/:modelId",
        id: "nest",
        dataModelIdKey: "modelId",
        defaultDisplayName: getContent("modelPageDefaultDisplayName"),
        component: ModelOverview,
        children: [
          {
            path: "/models/:modelId/run_simulation",
            id: "run_simulation",
            dataModelIdKey: "",
            defaultDisplayName: getContent("runSimResultPageDefaultDisplayName"),
            component: RunSimulation,
            children: [],
          },
          {
            path: "/models/:modelId/simulation_result/:resultId",
            id: "simulation_result_page",
            dataModelIdKey: "resultId",
            defaultDisplayName: getContent("simResultPageDefaultDisplayName"),
            component: SimulationResultPage,
            children: [],
          },
        ],
      },
    ],
  },
  {
    path: "/teams",
    id: "teams",
    defaultDisplayName: getContent("teamsPageDefaultDisplayName"),
    component: ConnectedTeamsPage,
    children: [
      {
        path: "/create_team",
        id: "create_team",
        defaultDisplayName: getContent("createTeamDisplayName"),
        component: ConnectedAddEditViewTeam,
        children: [],
      },
      {
        path: "/team/:teamId",
        id: "team_details",
        defaultDisplayName: getContent("teamDetailsDisplayName"),
        component: ConnectedAddEditViewTeam,
        children: [],
      },
    ],
  },
  {
    path: "/users",
    id: "users",
    defaultDisplayName: getContent("usersPageDefaultDisplayName"),
    component: ConnectedUsersPage,
    children: [
      {
        path: "/create_user",
        id: "create_user",
        defaultDisplayName: getContent("createUserDisplayName"),
        component: ConnectedAddEditViewUser,
        children: [],
      },
      {
        path: "/user/:userId",
        id: "user_details",
        defaultDisplayName: getContent("userDetailsDisplayName"),
        component: ConnectedAddEditViewUser,
        children: [],
      },
    ],
  },
  {
    path: "/subscriptions",
    id: "subscriptions",
    defaultDisplayName: getContent("subscriptionsPageDefaultDisplayName"),
    component: ConnectedSubscriptionsPage,
    children: [
      {
        path: "/create_subscription",
        id: "create_subscription",
        defaultDisplayName: getContent("createSubscriptionDisplayName"),
        component: ConnectedAddEditViewSubscription,
        children: [],
      },
      {
        path: "/subscription/:subscriptionId",
        id: "subscription_details",
        defaultDisplayName: getContent("subscriptionDetailsDisplayName"),
        component: ConnectedAddEditViewSubscription,
        children: [],
      },
    ],
  },
];

export const navItems: NavItem[] = buildNavTree(navigationTree);
export const staticPages: StaticPage[] = [
  {
    path: "/",
    id: "landing",
    component: ConnectedLanding,
  },
  {
    path: "/login",
    id: "login",
    component: ConnectedLoginPage,
  },
  {
    path: "/statement",
    id: "statement",
    component: StatementPage,
  },
  {
    path: "/accept_invite",
    id: "accept_invite",
    component: ConnectedSetPasswordPage,
  },
  {
    path: "/authenticated",
    id: "authenticated",
    component: Authenticated,
  },
  {
    path: "/user_guide",
    id: "user_guide",
    component: UserGuidePage,
  },
  {
    path: "/technical_guide",
    id: "technical_guide",
    component: TechnicalGuidePage,
  },
  {
    path: "/terms",
    id: "terms",
    component: TermsPage,
  },
  {
    path: "/privacy",
    id: "privacy",
    component: PrivacyPage,
  },
  {
    path: "*",
    id: "404",
    component: NotFoundPage,
  },
];
