import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { strings } from "content";
import CircularProgress from "@material-ui/core/CircularProgress";

export interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  description?: string;
  onSubmit: () => void;
  onTextChange: (name: string) => void;
  textFieldLabel: string;
  textFieldValue?: string;
  submitActionLoading?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    textField: {
      width: "100%",
    },
  }),
);

const UpdateTextDialog: React.FC<Props> = (props) => {
  const { open, title, onClose, onSubmit, description, submitActionLoading } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={(): void => {
        if (!submitActionLoading) {
          onClose();
        }
      }}
      fullWidth={true}
      maxWidth={"sm"}
      data-testid="update-text-dialog"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {description && <DialogContentText>{description}</DialogContentText>}
        <TextField
          onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
            props.onTextChange(event.target.value);
          }}
          className={classes.textField}
          variant="outlined"
          fullWidth
          data-testid="update-text-dialog-textfield"
          label={props.textFieldLabel}
          value={props.textFieldValue}
          disabled={submitActionLoading}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          disabled={submitActionLoading}
          onClick={onClose}
          color="primary"
          data-testid="update-text-cancel-button"
        >
          {strings.updateTextDialogCancelButtonText}
        </Button>
        <Button
          variant="contained"
          onClick={onSubmit}
          color="primary"
          data-testid="update-text-submit-button"
          disabled={submitActionLoading}
          endIcon={submitActionLoading ? <CircularProgress color="inherit" size={20} /> : undefined}
        >
          {strings.updateTextDialogSubmitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateTextDialog;
