import React from "react";
import { Typography, Button, Box } from "@material-ui/core";

interface Props {
  errorMessage: string;
  details?: string[];
  retryAction: () => void;
}

// eslint-disable-next-line react/display-name
const Retry = React.memo((props: Props) => {
  return (
    <Box display="flex" justifyContent="center" m={8} data-test-id="retry">
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="body1">{props.errorMessage}</Typography>
        {props.details &&
          props.details.map((message, index) => (
            <Typography key={index} variant="body2">
              {message}
            </Typography>
          ))}
        <Box m={2} />
        <Button variant="contained" onClick={props.retryAction} data-test-id="retry-button">
          Retry
        </Button>
      </Box>
    </Box>
  );
});

export default Retry;
