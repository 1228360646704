import { contentOrNull } from "./../content/index";
import _ from "lodash";
import roundTo from "round-to";
import { DisplayNameIndexes } from "types/table";
import moment from "moment";

function capitalizeFirstLetter(word: string): string {
  if (word) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  }
  return word;
}

export function titleCase(str: string): string {
  return _.startCase(_.toLower(str));
}

export const snakeToCamel = (str: string): string =>
  str.replace(/([_][a-z0-9])/g, (group) => group.toUpperCase().replace("_", ""));

export const DISPLAY_NAME_KEY_PREFIX = "displayNameKey";

/** Takes a key like some_key and makes it presentable by first checking if
 *  a specify display name has been provided else adding a space
 *  and turning it to title case eg: Some key.
 */
export function getDisplayName(key: string, lookupPrefix = DISPLAY_NAME_KEY_PREFIX): string {
  const predefinedDisplayName = contentOrNull(lookupPrefix + "_" + key);
  if (predefinedDisplayName) {
    return predefinedDisplayName;
  }
  const camelCased = snakeToCamel(key);
  const withSpaces = camelCased
    .replace(/([A-Z]|\d+)/g, " $1")
    .replace(/^./, function (str) {
      return str.toUpperCase();
    })
    .trim()
    .replace("  ", " ")
    .replace("- ", "-");
  return capitalizeFirstLetter(withSpaces);
}

export function displayNameToCamelCase(name: string): string {
  return name.toLowerCase().replace(/\s+(.)/g, function (match, group) {
    return group.toUpperCase();
  });
}

export function displayMoney(value: number): string {
  if (value < 100) {
    return `${value.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    })}`;
  } else if (value >= 100) {
    return `${value.toLocaleString(undefined, {
      maximumFractionDigits: 0,
    })}`;
  }
  return `${value}`;
}

export function displayNumber(value: number): string {
  return `${roundTo(value, 2).toLocaleString(undefined, {
    maximumFractionDigits: 2,
  })}`;
}

export function displayPercentage(value: number): string {
  return `${roundTo(value * 100, 2)} %`;
}

export function displaySeconds(secs: number): string {
  const duration = moment.duration(secs, "seconds");
  let result = "";
  if (duration.hours()) {
    result += `${duration.hours()} hrs `;
  }
  if (duration.minutes()) {
    result += `${duration.minutes()} min `;
  }
  if (duration.seconds()) {
    result += `${duration.seconds()} s`;
  }

  return result;
}

/**
 *
 * @param keys The keys that you need display names for
 * @param lookupPrefix A prefix used when looking up for possible display name in translation files
 *                     before creating a display name from key
 */
export const generateDisplayNameIndexes = <Key extends string>(
  keys: Key[],
  lookupPrefix?: string,
): DisplayNameIndexes<Key> => {
  return {
    keyToDisplayName: keys.reduce<Record<Key, string>>(
      (acc, curKey) => ({ ...acc, [curKey]: getDisplayName(curKey, lookupPrefix) }),
      {} as Record<Key, string>,
    ),
    displayNameToKey: keys.reduce<Record<string, Key>>(
      (acc, curKey) => ({ ...acc, [getDisplayName(curKey, lookupPrefix)]: curKey }),
      {},
    ),
  };
};
