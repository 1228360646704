import * as t from "io-ts";
import { DateType } from "utils/validatorTypes";

export const SubscriptionValidator = t.type({
  createdAt: DateType,
  updatedAt: DateType,
  id: t.string,
  name: t.string,
  maxNumberOfSeats: t.union([t.number, t.null]),
  seatsInUse: t.number,
});

export type Subscription = t.TypeOf<typeof SubscriptionValidator>;
