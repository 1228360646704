import * as t from "io-ts";
import { DateType } from "utils/validatorTypes";
import { Subscription } from "./subscriptions";

export const RoleEnumValidator = t.union([
  t.literal("subscription-admin"),
  t.literal("system-admin"),
  t.literal("user"),
]);
export type RoleEnum = t.TypeOf<typeof RoleEnumValidator>;

export const RolesValidator = t.intersection([
  t.type({
    id: t.string,
    role: RoleEnumValidator,
    subscriptionId: t.string,
  }),
  t.partial({ subscriptionName: t.string }),
]);
export type Role = t.TypeOf<typeof RolesValidator>;

export const SlimUserValidator = t.type({
  name: t.string,
  email: t.string,
  id: t.string,
});
export type SlimUser = t.TypeOf<typeof SlimUserValidator>;

export const UserValidator = t.intersection([
  SlimUserValidator,
  t.intersection([
    t.type({
      roles: t.array(RolesValidator),
      createdAt: DateType,
      updatedAt: DateType,
      active: t.boolean,
      invitationStatus: t.union([
        t.literal("user_created"),
        t.literal("invited"),
        t.literal("invite_accepted"),
        t.literal("has_logged_in"),
      ]),
    }),
    t.partial({
      termsAccepted: t.union([t.boolean, t.null]),
    }),
  ]),
]);

export type User = t.TypeOf<typeof UserValidator>;

export const AuthTokenValidator = t.type({
  token: t.string,
});

export type AuthToken = t.TypeOf<typeof AuthTokenValidator>;

export interface CurrentUser extends User {
  activeSubscription: Role | undefined;
}

// The order of roles indicate priority
export const ALL_ROLES: RoleEnum[] = ["system-admin", "subscription-admin", "user"];

export const enrichRoles = (roles: Role[], subscriptions: Subscription[]): Role[] => {
  const subscriptionsIndex = subscriptions.reduce<Record<string, Subscription>>((prev, current) => {
    return { ...prev, [current.id]: current };
  }, {});
  return roles.map((role) => {
    return { ...role, subscriptionName: subscriptionsIndex[role.subscriptionId]?.name || role.subscriptionName };
  });
};
