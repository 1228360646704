import _ from "lodash";
import hash from "object-hash";
import memoizeOne from "memoize-one";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepMerge(obj1: Record<string, unknown>, obj2: Record<string, unknown>): Record<string, any> {
  return _.mergeWith({}, obj1, obj2, function (a, b) {
    if (_.isArray(a)) {
      return b.concat(a);
    }
  });
}

export function typeSafeFor<M, K extends keyof M>(map: M, callback: (key: keyof M, value: M[K]) => void): void {
  (Object.keys(map) as Array<K>).map((key: K) => callback(key, map[key]));
}

export function typeSafeMap<Result, M, K extends keyof M>(
  map: M,
  callback: (key: keyof M, value: M[K]) => Result,
): Result[] {
  return (Object.keys(map) as Array<K>).map((key: K) => callback(key, map[key]));
}

export function typeSafeFilter<Result, M, K extends keyof M>(
  map: M,
  callback: (key: keyof M, value: M[K]) => boolean,
): [K, M[K]][] {
  return (Object.entries(map) as [K, M[K]][]).filter((entry: [K, M[K]]) => callback(entry[0], entry[1]));
}

export function typeSafeReduce<Result, M, K extends keyof M>(
  map: M,
  callback: (prev: Result, key: keyof M, value: M[K]) => Result,
  initialValue: Result,
): Result {
  return (Object.keys(map) as Array<K>).reduce((prev: Result, key: K) => callback(prev, key, map[key]), initialValue);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function memoize<T extends (this: any, ...newArgs: any[]) => ReturnType<T>>(fn: T): T {
  return memoizeOne(fn, _.isEqual);
}

export function deepCopy<T>(obj: T): T {
  return _.cloneDeep(obj);
}

/** A special function to be used to compare properties when memoizing components.
 * It will iterate through the properties of properties and apply appropriate comparison
 * techniques that results in expected render decisions
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function memoCompare<T extends Record<string, any>>(prev: T, newProps: T): boolean {
  let isSame = true;
  _.some(prev, (value, key) => {
    // If the value is an object then compare the hash of the values because
    // {} !== {} and a deep compare might be too slow in big objects
    if (typeof value === "object" && value !== null) {
      if (hash(value) !== hash(newProps[key])) {
        isSame = false;
        return true;
      }
    }
    // There is no good way to compare the contents of a function including its local
    // scope so we currently assume functions not to change between renders
    else if (typeof value === "function") {
      return false;
    }
    // The default case
    else if (value !== newProps[key]) {
      isSame = false;
      return true;
    }
    return false;
  });
  return isSame;
}

/** Removes attributes defined as undefined */
export function pruneObject<T>(obj: T): T {
  return typeSafeReduce(
    obj,
    (prev, key, value) => {
      if (value === undefined) {
        return prev;
      }
      return { ...prev, [key]: value };
    },
    {} as T,
  );
}

/** Determin if two matrices have the same dimensions */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function dimensionsAreEqual(matrix1: any[], matrix2: any[]): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (matrix1.length !== matrix2.length) {
    return false;
  }
  const results = _.zip(matrix1, matrix2).map((items) => {
    const [item1, item2] = items;
    // If both items are arrays then recursively call checkElementsMatch
    if (Array.isArray(item1) && Array.isArray(item2)) {
      return dimensionsAreEqual(item1, item2);
    }
    // If both items are not arrays then they are just normal values so proceed
    else if (!Array.isArray(item1) && !Array.isArray(item2)) {
      return true;
    }
    // To get here one of the values is an array and one of them isn't. This is not valid
    // so return false
    else {
      return false;
    }
  });
  return results.every((value) => value);
}

export function compareArrays<T>(newArray: T[], baseArray: T[]): [T[], T[]] {
  const missingKeys: T[] = [];
  const unexpectedKeys: T[] = [];

  const newArraySet = new Set(newArray);
  const baseArraySet = new Set(baseArray);

  newArray.forEach((item) => {
    if (!baseArraySet.has(item)) {
      unexpectedKeys.push(item);
    }
  });
  baseArraySet.forEach((item) => {
    if (!newArraySet.has(item)) {
      missingKeys.push(item);
    }
  });

  return [missingKeys, unexpectedKeys];
}
