export const UPDATE_STATE_PARAMS = "UPDATE_STATE_PARAMS";
export type UPDATE_STATE_PARAMS = typeof UPDATE_STATE_PARAMS;

export const UPDATE_SIMULATION_MODEL = "UPDATE_SIMULATION_MODEL";
export type UPDATE_SIMULATION_MODEL = typeof UPDATE_SIMULATION_MODEL;

export const UPDATE_SIMULATION_MODEL_SUMMARIES = "UPDATE_SIMULATION_MODEL_SUMMARIES";
export type UPDATE_SIMULATION_MODEL_SUMMARIES = typeof UPDATE_SIMULATION_MODEL_SUMMARIES;

export const UPDATE_SIMULATION_MODEL_SUMMARY = "UPDATE_SIMULATION_MODEL_SUMMARY";
export type UPDATE_SIMULATION_MODEL_SUMMARY = typeof UPDATE_SIMULATION_MODEL_SUMMARY;

export const UPDATE_SIMULATION_RESULT = "UPDATE_SIMULATION_RESULT";
export type UPDATE_SIMULATION_RESULT = typeof UPDATE_SIMULATION_RESULT;

export const DELETE_SIMULATION_RESULT = "DELETE_SIMULATION_RESULT";
export type DELETE_SIMULATION_RESULT = typeof DELETE_SIMULATION_RESULT;

export const UPDATE_SIMULATION_RESULT_SUMMARY_LIST = "UPDATE_SIMULATION_RESULT_SUMMARY_LIST";
export type UPDATE_SIMULATION_RESULT_SUMMARY_LIST = typeof UPDATE_SIMULATION_RESULT_SUMMARY_LIST;

export const DELETE_SIMULATION_MODEL = "DELETE_SIMULATION_MODEL";
export type DELETE_SIMULATION_MODEL = typeof DELETE_SIMULATION_MODEL;

export const UPDATE_SIMULATION_MODEL_LIST = "UPDATE_SIMULATION_MODEL_LIST";
export type UPDATE_SIMULATION_MODEL_LIST = typeof UPDATE_SIMULATION_MODEL_LIST;

export const API_META = "API_META";
export type API_META = typeof API_META;

export const API_REQUEST = "API_REQUEST";
export type API_REQUEST = typeof API_REQUEST;

export const UPDATE_API_REQUEST = "UPDATE_API_REQUEST";
export type UPDATE_API_REQUEST = typeof UPDATE_API_REQUEST;

export const UPDATE_DATAMODEL_TITLES = "UPDATE_DATAMODEL_TITLES";
export type UPDATE_DATAMODEL_TITLES = typeof UPDATE_DATAMODEL_TITLES;

export const USER_LOGOUT = "USER_LOGOUT";
export type USER_LOGOUT = typeof USER_LOGOUT;

export const SET_SIMULATION_MODEL_DRAFT = "SET_SIMULATION_MODEL_DRAFT";
export type SET_SIMULATION_MODEL_DRAFT = typeof SET_SIMULATION_MODEL_DRAFT;

export const UPLOAD_LOAN_DATA_TAPE = "UPLOAD_LOAN_DATA_TAPE";
export type UPLOAD_LOAN_DATA_TAPE = typeof UPLOAD_LOAN_DATA_TAPE;

export const UPLOAD_SECURITIES_DATA_TAPE = "UPLOAD_SECURITIES_DATA_TAPE";
export type UPLOAD_SECURITIES_DATA_TAPE = typeof UPLOAD_SECURITIES_DATA_TAPE;

export const UPLOAD_COLLATERALS_DATA_TAPE = "UPLOAD_COLLATERALS_DATA_TAPE";
export type UPLOAD_COLLATERALS_DATA_TAPE = typeof UPLOAD_COLLATERALS_DATA_TAPE;

export const SET_ACTIVE_SUBSCRIPTION_USER = "SET_ACTIVE_SUBSCRIPTION_USER";
export type SET_ACTIVE_SUBSCRIPTION_USER = typeof SET_ACTIVE_SUBSCRIPTION_USER;

export const UPDATE_ROLE = "UPDATE_ROLE";
export type UPDATE_ROLE = typeof UPDATE_ROLE;

export const DELETE_ROLE = "DELETE_ROLE";
export type DELETE_ROLE = typeof DELETE_ROLE;

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export type SET_CURRENT_USER = typeof SET_CURRENT_USER;

export const UPDATE_USER_LIST = "UPDATE_USER_LIST";
export type UPDATE_USER_LIST = typeof UPDATE_USER_LIST;

export const UPDATE_USER = "UPDATE_USER";
export type UPDATE_USER = typeof UPDATE_USER;

export const DELETE_USER = "DELETE_USER";
export type DELETE_USER = typeof DELETE_USER;

export const UPDATE_SUBSCRIPTION_LIST = "UPDATE_SUBSCRIPTION_LIST";
export type UPDATE_SUBSCRIPTION_LIST = typeof UPDATE_SUBSCRIPTION_LIST;

export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export type UPDATE_SUBSCRIPTION = typeof UPDATE_SUBSCRIPTION;

export const UPDATE_TEAM_LIST = "UPDATE_TEAM_LIST";
export type UPDATE_TEAM_LIST = typeof UPDATE_TEAM_LIST;

export const UPDATE_TEAM = "UPDATE_TEAM";
export type UPDATE_TEAM = typeof UPDATE_TEAM;

export const DELETE_TEAM = "DELETE_TEAM";
export type DELETE_TEAM = typeof DELETE_TEAM;
