import { USER_LOGOUT } from "./../../constants/index";
import { createReducer } from "typesafe-actions";
import { TeamsState } from "types/store";
import { DELETE_TEAM, UPDATE_TEAM, UPDATE_TEAM_LIST } from "../../constants/index";
import { Team } from "store/models/teams";

// For more details on the design of this reducer see docs/redux_store_design.md#resource_storage
export const initialState: TeamsState = {
  items: {},
  lists: {},
};

export const reducer = createReducer(initialState)
  .handleAction(USER_LOGOUT, () => {
    return initialState;
  })
  .handleAction(DELETE_TEAM, (state, action) => {
    const { [action.payload]: deletedTeam, ...otherTeams } = state.items; // eslint-disable-line @typescript-eslint/no-unused-vars
    return {
      ...state,
      items: otherTeams,
    };
  })
  .handleAction(UPDATE_TEAM, (state, action) => {
    return {
      ...state,
      items: {
        ...state.items,
        [action.payload.id]: action.payload,
      },
    };
  })
  .handleAction(UPDATE_TEAM_LIST, (state, action) => {
    return {
      ...state,
      lists: {
        ...state.lists,
        [action.payload.listId]: {
          items: action.payload.items.map((team) => team.id),
          fetchedAt: new Date(Date.now()),
        },
      },
      items: {
        ...state.items,
        ...action.payload.items.reduce((acc, team) => ({ ...acc, [team.id]: team }), {} as Record<string, Team>),
      },
    };
  });

// Store accessors

export const getTeam = (state: TeamsState, teamId: string): Team | void => {
  return state.items[teamId];
};

export const getTeams = (state: TeamsState, listId: string): Team[] | void => {
  const list = state.lists[listId];
  if (list) {
    return list.items.map((itemId) => state.items[itemId]);
  }
};
