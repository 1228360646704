import React from "react";
import { NavItemProps, PathItem } from "utils/navigation";
import { withRouter, RouteComponentProps, generatePath } from "react-router-dom";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { Breadcrumbs, Link } from "@material-ui/core";
import { StoreState } from "types/store";
import { connect } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      cursor: "pointer",
      color: theme.palette.common.white,
      fontSize: 12,
    },
  }),
);

interface ComponentProps {
  currentPage: NavItemProps;
}
interface StoreProps {
  dataModelTitles: Record<string, string>;
}
interface DispatchProps {}
type Props = ComponentProps & DispatchProps & StoreProps & RouteComponentProps;

export const BreadCrumbs: React.FC<Props> = (props) => {
  const classes = useStyles();

  const handleBreadcrumbClick = (item: PathItem) => (): void => {
    const compiledPath = generatePath(item.path, props.match.params);
    props.history.push(compiledPath);
  };

  const pathItems = props.currentPage.pathItems;
  if (pathItems.length <= 1) {
    return <div></div>;
  }
  return (
    <Breadcrumbs data-testid="breadcrumb" aria-label="breadcrumb" className={classes.link}>
      {pathItems.map((item: PathItem) => {
        const dataModelTitle =
          props.dataModelTitles[(props.match.params as Record<string, string>)[item.dataModelIdKey || ""]];
        return (
          <Link key={item.id} color="inherit" onClick={handleBreadcrumbClick(item)}>
            {dataModelTitle || item.defaultDisplayName}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

const mapStateToProps = (state: StoreState): StoreProps => {
  return {
    dataModelTitles: state.navigation.dataModelTitles,
  };
};

export default connect(mapStateToProps)(withRouter(BreadCrumbs));
