import { UPDATE_SIMULATION_RESULT_SUMMARY_LIST, USER_LOGOUT } from "../../constants/index";
import { SimulationResultSummaryState, StoreState } from "types/store";
import { createReducer } from "typesafe-actions";
import { SimulationResultSummary } from "store/models/simulationResult";

// For more details on the design of this reducer see docs/redux_store_design.md#resource_storage
export const initialState: SimulationResultSummaryState = {
  items: {},
  lists: {},
};

export const reducer = createReducer(initialState)
  .handleAction(USER_LOGOUT, () => {
    return initialState;
  })
  .handleAction(UPDATE_SIMULATION_RESULT_SUMMARY_LIST, (state, action) => {
    const newItemsRecord = action.payload.items.reduce(
      (object: Record<string, SimulationResultSummary>, item: SimulationResultSummary) => {
        object[item.id] = item;
        return object;
      },
      {},
    );

    return {
      ...state,
      items: {
        ...state.items,
        ...newItemsRecord,
      },
      lists: {
        ...state.lists,
        [action.payload.listId]: {
          items: action.payload.items.map((model) => model.id),
          fetchedAt: new Date(Date.now()),
        },
      },
    };
  });

export const getSimulationResultSummaries = (
  state: SimulationResultSummaryState,
  listId: string,
): SimulationResultSummary[] => (state.lists[listId] ? state.lists[listId].items.map((id) => state.items[id]) : []);

export const getSimulationResultSummary = (state: StoreState, resultId: string): SimulationResultSummary =>
  state.simulationResultSummaries.items[resultId];
