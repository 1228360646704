import enStrings from "./en";
import enParamDescriptions from "./parameterDescriptions";

import LocalizedStrings, { LocalizedStringsMethods } from "react-localization";
import { ErrorModel } from "store/models/errors";

type RequiredContent = { [key in ErrorModel["key"]]: string };

export type Content = typeof enStrings & typeof enParamDescriptions & RequiredContent;

export interface LocalisedContent extends LocalizedStringsMethods, Content {
  score: string;
  time: string;
}

export const strings = new LocalizedStrings<Content>({
  en: { ...enStrings, ...enParamDescriptions },
});

export function parse(str: string, ...val: (string | number)[]): string {
  for (let index = 0; index < val.length; index++) {
    str = str.replace(new RegExp(`\\{${index}\\}`, "g"), String(val[index]));
  }
  return str;
}

export const getContent = (key: string): string => {
  return strings[key as keyof Content] || key;
};

export const contentOrNull = (key: string): string | null => {
  return strings[key as keyof Content] || null;
};
