import React from "react";
import { WithStyles, createStyles, withStyles, Box } from "@material-ui/core";
import Loader from "components/Loader";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const styles = (): any =>
  createStyles({
    page: {
      height: "100vh",
    },
  });

type Props = WithStyles<typeof styles>;

const NotFound: React.FC<Props> = (props) => {
  const { classes } = props;

  return (
    <Box className={classes.page} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Loader />
    </Box>
  );
};

export default withStyles(styles)(NotFound);
