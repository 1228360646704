import { getContent, parse, strings } from "./../content/index";
export interface FieldValidity {
  isValid: boolean;
  invalidReason: string;
}

/**
 *
 * @param fieldName The display name of the field
 * @param fieldValue The value generated by the actual text field. Note, if the value is set from some other source on
 *                   first load textField might be displaying some value, but this attribute will still be empty
 * @param derivedValue This is the actual value that is showing on the screen. This might come from the value typed by the user
 *                     or from some other source.
 */
export const validateTextField = (fieldName: string, fieldValue?: string, derivedValue?: string): FieldValidity => {
  const _derivedValue = derivedValue || fieldValue;
  if (_derivedValue === "") {
    return { isValid: false, invalidReason: parse(getContent("textFieldMustNotBeEmpty"), fieldName) };
  }
  return { isValid: true, invalidReason: "" };
};

interface ValidateIntTextFieldOptions {
  min?: number;
  max?: number;
  belowMinErrorMessage?: string;
  aboveMaxErrorMessage?: string;
  nullable?: boolean;
}

/**
 *
 * @param fieldName The display name of the field
 * @param fieldValue The value generated by the actual text field. Note, if the value is set from some other source on
 *                   first load textField might be displaying some value, but this attribute will still be empty
 * @param derivedValue This is the actual value that is showing on the screen. This might come from the value typed by the user
 *                     or from some other source.
 * @param options Additional options that influence the parameters used during validation
 */
export const validateIntTextField = (
  fieldName: string,
  fieldValue?: string,
  derivedValue?: string,
  options: ValidateIntTextFieldOptions = {},
): FieldValidity => {
  const _derivedValue = derivedValue || fieldValue;
  if (options.nullable && (_derivedValue === null || _derivedValue === undefined || _derivedValue === "")) {
    return { isValid: true, invalidReason: "" };
  } else if (_derivedValue === "") {
    return { isValid: false, invalidReason: parse(strings.textFieldMustNotBeEmpty, fieldName) };
  }
  if (Number.isInteger(Number(_derivedValue))) {
    const value = Number(_derivedValue);
    if (options.min !== undefined && value < options.min) {
      return {
        isValid: false,
        invalidReason: options.belowMinErrorMessage || parse(strings.textFieldMustBeAboveValue, fieldName, options.min),
      };
    } else if (options.max !== undefined && value > options.max) {
      return {
        isValid: false,
        invalidReason: options.aboveMaxErrorMessage || parse(strings.textFieldMustBeBelowValue, fieldName, options.max),
      };
    }
  } else {
    return { isValid: false, invalidReason: parse(strings.textFieldMustBeInteger, fieldName) };
  }
  return { isValid: true, invalidReason: "" };
};

export const validatePasswordTextField = (fieldName: string, password?: string): FieldValidity => {
  // If there is no text it means the user hasn't yet typed anything. Therefor we should not say it is
  // invalid
  if (password === undefined) {
    return { isValid: true, invalidReason: "" };
  }
  if (password.length < 8) {
    return { isValid: false, invalidReason: parse(getContent("passwordTooShortErrorMessage"), fieldName) };
  }
  if (!new RegExp("[A-Z]").test(password)) {
    return { isValid: false, invalidReason: parse(getContent("passwordTooFewUpperCaseCaseErrorMessage"), fieldName) };
  }
  if (!new RegExp("[a-z]").test(password)) {
    return { isValid: false, invalidReason: parse(getContent("passwordTooFewLowerCaseErrorMessage"), fieldName) };
  }
  if (!new RegExp("[0-9]").test(password)) {
    return { isValid: false, invalidReason: parse(getContent("passwordTooFewNumbersErrorMessage"), fieldName) };
  }
  return { isValid: true, invalidReason: "" };
};

export const validateConfirmPasswordTextField = (password?: string, confirmPassword?: string): FieldValidity => {
  // If there is no text it means the user hasn't yet typed anything. Therefor we should not say it is
  // invalid
  if (confirmPassword === undefined) {
    return { isValid: true, invalidReason: "" };
  }
  if (confirmPassword !== password) {
    return { isValid: false, invalidReason: getContent("passwordsMustMatchErrorMessage") };
  }
  return { isValid: true, invalidReason: "" };
};
