import { ListQueryParams, withSubscriptionId, ThunkResponse } from "./../../utils/api";
import { AuthToken, AuthTokenValidator, RoleEnum } from "./../models/users";
import queryString from "query-string";
import * as t from "io-ts";
import {
  DeleteUserAction,
  UpdateUserAction,
  UpdateUserListAction,
  ApiRequestAction,
  ApiRequstCallbackAction,
  ApiRequestMeta,
} from "./../../types/store";
import * as constants from "../../constants";
import { User, UserValidator } from "store/models/users";
import { generateRequestId } from "utils/api";
import { apiRequest } from "./api";

export function updateUsersList(users: User[], listId: string): UpdateUserListAction {
  return {
    type: constants.UPDATE_USER_LIST,
    payload: {
      listId,
      items: users,
    },
  };
}

export function updateUser(user: User): UpdateUserAction {
  return {
    type: constants.UPDATE_USER,
    payload: user,
  };
}

export function deleteUser(id: string): DeleteUserAction {
  return {
    type: constants.DELETE_USER,
    payload: id,
  };
}

export const FETCH_USERS_REQUEST_ID = "fetchUsersRequest";
export const fetchUsers = (listId: string, queryParams?: ListQueryParams): ThunkResponse<User[]> => {
  const onSuccess: ApiRequstCallbackAction<User[]> = (results) => (dispatch): void => {
    dispatch(updateUsersList(results, listId));
  };
  return withSubscriptionId((subscriptionId) => {
    const generatedQuery = queryParams
      ? queryString.stringify(
          {
            name: queryParams.query,
            limit: queryParams.limit,
            offset: queryParams.offset,
            "order_by": queryParams.orderBy,
            "sort_by": queryParams.sortBy,
          },
          { arrayFormat: "comma" },
        )
      : undefined;
    const _queryString = generatedQuery === "" ? "" : `?${generatedQuery}`;

    const requestMeta: ApiRequestMeta<User[]> = {
      validator: t.array(UserValidator),
      onSuccessAction: onSuccess,
      onErrorAction: () => (): void => {},
      url: `/subscription/${subscriptionId}/users${_queryString}`,
      method: "GET",
      headers: {},
      id: generateRequestId(FETCH_USERS_REQUEST_ID, listId),
    };
    return apiRequest(requestMeta);
  });
};

export const DELETE_USER_REQUEST_ID = "deleteUserRequest";
export const deleteUserRequest = (userId: string, onComplete?: () => void): ThunkResponse<unknown> => {
  const onSuccess: ApiRequstCallbackAction<unknown> = () => (): void => {
    if (onComplete) {
      onComplete();
    }
  };
  return withSubscriptionId((subscriptionId) => {
    const requestMeta: ApiRequestMeta<unknown> = {
      onSuccessAction: onSuccess,
      onErrorAction: () => (): void => {},
      url: `/subscription/${subscriptionId}/user/${userId}`,
      method: "DELETE",
      headers: {},
      id: generateRequestId(DELETE_USER_REQUEST_ID, userId),
    };
    return apiRequest(requestMeta);
  });
};

export interface CreateUserPayload {
  name: string;
  email: string;
  role: RoleEnum;
}

export const CREATE_USER_REQUEST_ID = "createUsersRequest";
export const createUser = (
  createUserPayload: CreateUserPayload,
  createdId?: (id: string) => void,
): ThunkResponse<User> => {
  const onSuccess: ApiRequstCallbackAction<User> = (result) => (dispatch): void => {
    dispatch(updateUser(result));
    if (createdId) {
      createdId(result.id);
    }
  };
  return withSubscriptionId((subscriptionId) => {
    const requestMeta: ApiRequestMeta<User> = {
      validator: UserValidator,
      onSuccessAction: onSuccess,
      onErrorAction: () => (): void => {},
      url: `/subscription/${subscriptionId}/users`,
      method: "POST",
      body: createUserPayload,
      headers: {},
      id: generateRequestId(CREATE_USER_REQUEST_ID, ""),
    };
    return apiRequest(requestMeta);
  });
};

export const FETCH_USER_REQUEST_ID = "getUsersRequest";
export const fetchUser = (userId: string): ThunkResponse<User> => {
  const onSuccess: ApiRequstCallbackAction<User> = (result) => (dispatch): void => {
    dispatch(updateUser(result));
  };
  return withSubscriptionId((subscriptionId) => {
    const requestMeta: ApiRequestMeta<User> = {
      validator: UserValidator,
      onSuccessAction: onSuccess,
      onErrorAction: () => (): void => {},
      url: `/subscription/${subscriptionId}/user/${userId}`,
      method: "GET",
      headers: {},
      id: generateRequestId(FETCH_USER_REQUEST_ID, userId),
    };
    return apiRequest(requestMeta);
  });
};

export interface PatchUserPayload extends Partial<Pick<User, "name" | "email" | "termsAccepted" | "roles">> {
  active?: boolean;
}

export const PATCH_USER_REQUEST_ID = "patchUsersRequest";
export const patchUser = (
  userId: string,
  patchUserPayload: PatchUserPayload,
  onComplete?: () => void,
): ThunkResponse<User> => {
  const onSuccess: ApiRequstCallbackAction<User> = (result) => (dispatch): void => {
    dispatch(updateUser(result));
    if (onComplete) {
      onComplete();
    }
  };
  return withSubscriptionId((subscriptionId) => {
    const requestMeta: ApiRequestMeta<User> = {
      validator: UserValidator,
      onSuccessAction: onSuccess,
      onErrorAction: () => (): void => {},
      url: `/subscription/${subscriptionId}/user/${userId}`,
      method: "PATCH",
      body: patchUserPayload,
      headers: {},
      id: generateRequestId(PATCH_USER_REQUEST_ID, userId),
    };
    return apiRequest(requestMeta);
  });
};

export const SET_PASSWORD_REQUEST_ID = "setPasswordRequest";
export const setPassword = (inviteId: string, password: string, onComplete?: () => void): ApiRequestAction<User> => {
  const onSuccess: ApiRequstCallbackAction<User> = (result) => (dispatch): void => {
    dispatch(updateUser(result));
    if (onComplete) {
      onComplete();
    }
  };

  const requestMeta: ApiRequestMeta<User> = {
    validator: UserValidator,
    onSuccessAction: onSuccess,
    onErrorAction: () => (): void => {},
    url: `/users/set_password`,
    method: "POST",
    body: { token: inviteId, tokenType: "invite", password },
    headers: {},
    id: generateRequestId(SET_PASSWORD_REQUEST_ID, inviteId),
  };
  return apiRequest(requestMeta);
};

export const LOGIN_REQUEST_ID = "loginRequest";
export const login = (
  email: string,
  password: string,
  onComplete?: (authToken: AuthToken) => void,
): ApiRequestAction<AuthToken> => {
  const onSuccess: ApiRequstCallbackAction<AuthToken> = (result) => (): void => {
    if (onComplete) {
      onComplete(result);
    }
  };

  const requestMeta: ApiRequestMeta<AuthToken> = {
    validator: AuthTokenValidator,
    onSuccessAction: onSuccess,
    onErrorAction: () => (): void => {},
    url: `/users/login`,
    method: "POST",
    body: { email, password },
    headers: {},
    id: generateRequestId(LOGIN_REQUEST_ID, ""),
  };
  return apiRequest(requestMeta);
};
